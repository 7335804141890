
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Faq = () => {
  const ImportItems = [
    { id: 1, itemName: "Prescribed application form for Building Permission." },
    { id: 2, itemName: "Notarised Registry of the Plot." },
    { id: 3, itemName: "Nazool N.O.C./BDA N.O.C./Housing Board N.O.C. ( whichever applicable)" },
    { id: 4, itemName: "Affidavit for Building Permission" },
    { id: 5, itemName: "Map/Site Plans ( A set of one original Film and seven Blue Prints)" },
    { id: 6, itemName: "Municipal Corporation N.O.C. of respective Ward." },
    { id: 7, itemName: "Structural Stability Certificate of earthquake by competent structural Engineer/agency" },

  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Faq" />
          <PageHeaderImg BreadName1="Home" BreadName2="Faq" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Faq</div>
              <div className="card-body aboutus">
              <h5 className="card-title">What is the procedure for Registering and acquiring Birth/Death Certificate?</h5>
              <p>The event of birth or death is registered only in the division/area of occurrence. In case of events occurring in any medical institution or clinic, the doctor in-charge and in case of events occurring in houses, the head of the family or close relative has to inform local registrar of birth and deaths.</p>
              <h5 className="card-title">What is the time Limit for providing information to register Birth/Death? </h5>
              <p>Time limit for providing information for registration in case of death is 7 days and in case of birth it is 14 days.</p>
              <h5 className="card-title">While applying for Building Permission, What all documents are required? </h5>
              <p>Following documents are required for Building Permission at Nagar Nigam:</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <h5 className="card-title">What is the validity period for Building Permission? </h5>
                <p>Building Permission granted by Nagar Nigam is valid for one year. However it can be renewed for one more year, thereafter a new permission is required if building is not constructed by this time.</p>
                <h5 className="card-title">What Penalty can be imposed if a building is constructed without approval or there is a violation of approved plan? </h5>
                <p>Penalty equivalent to 15-50 times of regular Building Permission fees can be imposed in these cases.</p>
                <h5 className="card-title"> What is a Trade License?</h5>
                <p>Trade License is a license issued by the municipal corporation which gives you permission to carry on the particular trade or business for which it is issued. It does not grant ownership of property or permission for any other activity than for which it is issued.</p>
                <h5 className="card-title">Why do I need a trade license? </h5>
                <p>It is important for trade within the city to be regulated to ensure that the citizen is not adversely affected by Health Hazard & Nuisance by improper carrying of a trade. The trade license is a means to ensure that the manner in which the business is being carried on is according to the relevant rules, standards and safety guidelines.</p>
                <h5 className="card-title">If I don’t obtain my Trade License, what will be the result? </h5>
                <p>As per the RMC Act, running an unauthorized Trade is offense. The concerned authority may seize or lock your Trade business with or without intimation.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default Faq;