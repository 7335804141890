import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';
import './style.css';

const Smartcities = () => {
  const ImportItems = [
    { id: 1, itemName: "Adequate Water Supply" },
    { id: 2, itemName: "Assured Electric Supply" },
    { id: 3, itemName: "Proper Sanitation including Solid Waste Management" },
    { id: 4, itemName: "Facilitating efficient urban mobility and public transport system" },
    { id: 5, itemName: "Facilitate affordable housing (especially for poor)" },
    { id: 6, itemName: "Robust IT Connectivity and Digitization" },
    { id: 7, itemName: "Effective Governance, E-Governance and citizen participation" },
    { id: 8, itemName: "Sustainable Environment" },
    { id: 9, itemName: "Facilitating proper Health and Education system" },
    { id: 10, itemName: "Ensuring safety and security of Citizens" },
  ];

  const ImportItems2 = [
    { id: 1, itemName: "Public Information, Grievance Redressal" },
    { id: 2, itemName: "Electronic Service Delivery" },
    { id: 3, itemName: "Citizen Engagement" },
    { id: 4, itemName: "Citizens-City’s Eyes and Ears" },
    { id: 5, itemName: "Video Crime Monitoring" },
  ];

  const ImportItems3 = [
    { id: 1, itemName: "Waste to Energy and Fuel" },
    { id: 2, itemName: "Waste to Compost" },
    { id: 3, itemName: "Treatment of Waste Water" },
    { id: 4, itemName: "Recycling and Reduction C& D Waste" },
  ];

  const ImportItems4 = [
    { id: 1, itemName: "Smart Meters and Management" },
    { id: 2, itemName: "Leakage Identification, Preventive Maintenance" },
    { id: 3, itemName: "Water Quality Monitoring" },
  ];

  const ImportItems5 = [
    { id: 1, itemName: "Smart Meters and Management" },
    { id: 2, itemName: "Renewable Sources of Energy" },
    { id: 3, itemName: "Energy Efficient and Green Building" },
  ];
  const ImportItems6 = [
    { id: 1, itemName: "Smart parking" },
    { id: 2, itemName: "Intelligent Traffic Management" },
    { id: 3, itemName: "Integrated Multi-Modal Transport" },
  ];
  const ImportItems7 = [
    { id: 1, itemName: "Tele-Medicine and Tele-Education" },
    { id: 2, itemName: "Incubation/Trade Facilitation Center" },
    { id: 3, itemName: "Skill development Centers" },
  ];
  const ImportItems8 = [
    { id: 1, itemName: "Agra" },
    { id: 2, itemName: "Aligarh" },
    { id: 3, itemName: "Allahabad" },
    { id: 4, itemName: "Bareilly" },
    { id: 5, itemName: "Ghaziabad" },
    { id: 6, itemName: "Jhansi" },
    { id: 7, itemName: "Kanpur" },
    { id: 8, itemName: "Lucknow" },
    { id: 9, itemName: "Moradabad" },
    { id: 10, itemName: "Rampur" },
    { id: 11, itemName: "Saharanpur" },
    { id: 12, itemName: "Varanasi" },
  ];
  const ImportItems9 = [
    { id: 1, itemName: "Efficient land use by promoting mixed land use, planning for unplanned areas, flexibility in land use and building bye-laws to adapt to change." },
    { id: 2, itemName: "Planned expansion of the housing facilities for all citizens." },
    { id: 3, itemName: "Creation of road networks not only for vehicles and public transport but also for pedestrian and cyclists, reduction of congestion by creation of walkable localities, to reduce resources depletion and air pollution as well as promoting effective security for citizens." },
    { id: 4, itemName: "Development and conservation of open and recreational spaces like parks and playgrounds for escalating the quality of life of citizens, reducing urban heat effect and to maintain ecological balance within a city." },
    { id: 5, itemName: "Promoting various transport options like Transit Oriented Development (TOD), public transport and last mile Para transport connectivity." },
    { id: 6, itemName: "Making governance transparent and citizen-friendly by enhancing the utilization of online services, especially mobile to reduce cost of services and increase convenience for citizens in availing services without physically visiting the departments." },
    { id: 7, itemName: "Imparting identity to a city on the basis of its main economic activity, such as local cuisine, health, education, arts and craft, culture, sports goods, furniture, hosiery, textile, dairy, etc." },
    { id: 8, itemName: "Application of Smart Solutions to Infrastructure for economic advancement." },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Smart Cities Mission" />
          <PageHeaderImg BreadName1="Home" BreadName2="Smart Cities Mission" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Smart Cities Mission</div>
              <div className="card-body aboutus">
                <h5 className="card-title">What is Smart City?</h5>
                <p>A Smart city is a city equipped with basic infrastructure, clean and sustainable environment necessary for providing adequate quality of life through application of ecological friendly procedures, integration of technology, detailed planning as well as usage of Information technology in an efficient way.</p>
                <p>The basic idea behind creating certain selected cities into smart cities is to attract investors to those places by offering an effective platform to them that is equipped with good infrastructure and smart solutions that enables easy startup and working of the businesses.</p>
                <h5 className="card-title">Aim:</h5>
                <p>The main objective of creating smart cities is to improve quality of life of people by facilitating economic development, cleaner and healthier environment and richer cultural heritage by harnessing technology.</p>

                <h5 className="card-title">Core Elements of a Smart City</h5>
                <p>The two main aspects of a smart city are:</p>
                <h4 className="heading2">Basic Infrastructure</h4>
                <p>As the main aim of creating smart cities is to enhance the quality of life; thus, proper infrastructure creation is an important aspect of smart cities which includes following elements:</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <h4 className="heading2">Smart Solutions:</h4>
                <p>As the main objective of creating smart cities is to enhance economic development and quality of life by employment of technology that generates smart outcome, the core elements of smart solutions for a smart city are:</p>
                <h4 className="heading2">Smart Solutions related to E-Governance and Citizen Services:</h4>
                <ul>
                 {ImportItems2.map((e) => (
                   <li key={e.id}>
                     <i className="fa-solid fa-angles-right" /> {e.itemName}
                   </li>
                 ))}
               </ul>

               <h4 className="heading2">Smart Solutions related to Waste Management</h4>
                <ul>
                 {ImportItems3.map((e) => (
                   <li key={e.id}>
                     <i className="fa-solid fa-angles-right" /> {e.itemName}
                   </li>
                 ))}
               </ul>

               <h4 className="heading2">Smart Solutions related to Water Management</h4>
               <ul>
                {ImportItems4.map((e) => (
                  <li key={e.id}>
                    <i className="fa-solid fa-angles-right" /> {e.itemName}
                  </li>
                ))}
              </ul>

              <h4 className="heading2">Smart Solutions related to Energy Management</h4>
              <ul>
               {ImportItems5.map((e) => (
                 <li key={e.id}>
                   <i className="fa-solid fa-angles-right" /> {e.itemName}
                 </li>
               ))}
             </ul>
             <h4 className="heading2">Smart Solutions related to Urban Mobility</h4>
              <ul>
               {ImportItems6.map((e) => (
                 <li key={e.id}>
                   <i className="fa-solid fa-angles-right" /> {e.itemName}
                 </li>
               ))}
             </ul>
             <h5 className="card-title">Others</h5>
             <ul>
               {ImportItems7.map((e) => (
                 <li key={e.id}>
                   <i className="fa-solid fa-angles-right" /> {e.itemName}
                 </li>
               ))}
             </ul>
             <h4 className="heading2">Cities in Uttar Pradesh That Are Included in the List of Smart Cities Are:</h4>
             <ul>
              {ImportItems8.map((e) => (
                <li key={e.id}>
                  <i className="fa-solid fa-angles-right" /> {e.itemName}
                </li>
              ))}
            </ul>
            <h5 className="card-title">Key Features of development in the Smart Cities</h5>
        
            <h4 className="heading2">Key features of the development that are included in Smart City Mission are as follows:</h4>
             <ul>
              {ImportItems9.map((e) => (
                <li key={e.id}>
                  <i className="fa-solid fa-angles-right" /> {e.itemName}
                </li>
              ))}
            </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
       <FooterPre/>
      </div>
    </>
  );
};

export default Smartcities;
