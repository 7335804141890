import React from 'react'
import { Link } from 'react-router-dom';

const FooterPre = () => {
  return (
    <>
      <div className="prefooter pt-4">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="paragraph">
                Content on this website is published and Managed by Nagar Nigam
                Ayodhya, Uttar Pradesh. For any query regarding this website,
                Please contact the "
                <Link to="/webInfoManager">Web Information Manager</Link>". This
                is the official Website of Nagar Nigam Ayodhya, Government of
                Uttar Pradesh, India.
              </p>
            </div>
            <div className="col lefttext">
              <p>
                <b>Vistor No. - 2435206526</b>
              </p>
              <p>
                <b>
                  <Link to="https://3iconsulting.in/" className="linkcolor">
                    Powered by - 3i Consulting Private Limited
                  </Link>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterPre