import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";

const StreetLight = () => {
    const ImportItems = [
      { id: 1, itemName: "Providing street light on all the roads as per the provision." },
      { id: 2, itemName: "Installing High Masts on all the major traffic junctions." },
      { id: 3, itemName: "Providing Decorative Luminaries and Poles wherever necessary." },
      { id: 4, itemName: "Energy Management so as to cut down on the O&M expenses on electricity." },
      { id: 5, itemName: "Minimizing the Failure rate of the streetlights and hence providing maximum uptime by carrying out regular maintenance works."},
      { id: 6, itemName: "Installing the electricity polls and stretching wires."},
      { id: 7, itemName: "Installing and repairing traffic signals."},
      { id: 8, itemName: "Lightening arrangement at different public places and public festivals."},

  ];
  
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Street Light Department" />
          <PageHeaderImg BreadName1="Home" BreadName2="Street Light Department" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Street Light Department</div>
              <div className="card-body aboutus">
              <p>
                 Street Light Department of Nagar Nigam, Ayodhya plays a sole role in supplying of light to the city roads. Our major responsibilities are as follows:
                 
             </p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
};

export default StreetLight;
