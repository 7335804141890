import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Contactuslist = () => {
  const TableItems = [
    {
      id: 1,
      itemName: "Municipal Commissioner	",
      itemName1: "Mr. Santosh Kumar Sharma",
      itemName2: "7311165801",

    },
    {
      id: 2,
      itemName: "Additional Municipal Commissioner",
      itemName1: "Mr. Shashi Bhushan Rai",
      itemName2: "7311165818",
    },
    {
      id: 3,
      itemName: "Additional Municipal Commissioner",
      itemName1: "Mr. Vageesh Kumar Shukla",
      itemName2: "7311165831/8840402234",
    },
    {
      id: 4,
      itemName: "Additional Municipal Commissioner",
      itemName1: "Mr. Sumit Kumar",
      itemName2: "8299862275",
    },
    {
      id: 5,
      itemName: "Additional Municipal Commissioner",
      itemName1: "Mr. Anil Kumar Singh",
      itemName2: "9412012233",
    },
    {
      id: 6,
      itemName: "Assistant Municipal Commissioner/Zonal Officer Ayodhya Cantt.",
      itemName1: "Mr. Guru Prasad Panday",
      itemName2: "7311165831",
    },
    {
      id: 7,
      itemName: "Assistant Municipal Commissioner/Zonal Officer Awadhpuri",
      itemName1: "Mr. Ashok Kumar Gupta	",
      itemName2: "7311165805",
    },
    {
      id: 8,
      itemName: "Assistant Municipal Commissioner/Zonal Officer Kaushalpuri",
      itemName1: "Mr. Saurabh Nath	",
      itemName2: "7311165837",
    },
  ];

  const TableItems2 = [
    {
      id: 1,
      itemName: "Chief Engineer (Civil)	",
      itemName1: "Mr. Puneet Kumar Jha	",
      itemName2: "7311166466",
    },
    {
      id: 2,
      itemName: "Executive Engineer (Civil)",
      itemName1: "",
      itemName2: "7311165807",
    },
    {
      id: 3,
      itemName: "Assistant Engineer (Civil)	",
      itemName1: "Ms. Aastha Gupta",
      itemName2: "8004354159",
    },
    {
      id: 4,
      itemName: "Assistant Engineer (Civil)	",
      itemName1: "Mr. Bharat Verma	",
      itemName2: "6387085228",
    },
    {
      id: 5,
      itemName: "Assistant Engineer (Traffic)",
      itemName1: "Mr. Tinku Kumar	",
      itemName2: "9472945731/7979752471",
    },
    {
      id: 6,
      itemName: "Junior Engineer (Civil)",
      itemName1: "Mr. Rajpati Yadav	",
      itemName2: "7906410046",
    },
    {
      id: 7,
      itemName: "Junior Engineer (Civil)",
      itemName1: "Mr. Vinod Patel",
      itemName2: "9696586501/8707677079",
    },
    {
      id: 8,
      itemName: "Junior Engineer (Civil)",
      itemName1: "Mr. Arun Pratap Singh",
      itemName2: "",
    },
  ];

  const TableItems3 = [
    {
      id: 1,
      itemName: "Chief Assessing Officer",
      itemName1: "Mr. Gajendra Kumar",
      itemName2: "9454855566",
    },
    {
      id: 2,
      itemName: "Tax Assessment Officer	",
      itemName1: "Mr. Hargovind Singh Yadav",
      itemName2: "",
    },
    {
      id: 3,
      itemName: "Tax Superintendent",
      itemName1: "Mr. Chhote Lal Rawat",
      itemName2: "9026376617",
    },
    {
      id: 4,
      itemName: "Tax Superintendent",
      itemName1: "Mr. Vinod Gaud",
      itemName2: "9452284130",
    },
    {
      id: 5,
      itemName: "Tax Superintendent",
      itemName1: "Mr. Subhash Chandra Tripathi",
      itemName2: "9450044683",
    },
    {
      id: 6,
      itemName: "Tax Superintendent / MTS",
      itemName1: "Mr. Jai Prakash",
      itemName2: "8004175252",
    },
    {
      id: 7,
      itemName: "Tax Inspector",
      itemName1: "Mr. Anil Yadav",
      itemName2: "7311165814",
    },
    {
      id: 8,
      itemName: "Tax Inspector",
      itemName1: "Mr. Devi Prasad	",
      itemName2: "7311165816",
    },
    {
      id: 9,
      itemName: "Tax Inspector",
      itemName1: "Mrs Vandana",
      itemName2: "",
    },
    {
      id: 10,
      itemName: "Tax Inspector",
      itemName1: "Mr. Virendra Kumar",
      itemName2: "9721680606",
    },
  ];

  const TableItems4 = [
    {
      id: 1,
      itemName: "General Manager (Water)",
      itemName1: "Mr. Mahesh Chandra Azad",
      itemName2: "7311165803",
    },
    {
      id: 2,
      itemName: "Executive Engineer (Water)",
      itemName1: "Mr. Anoop Kumar Singh",
      itemName2: "7311165804",
    },
    {
      id: 3,
      itemName: "Assistant Engineer (Water)	",
      itemName1: " Mr. Jai Kumar",
      itemName2: "7754915325",
    },
    {
      id: 4,
      itemName: "Assistant Engineer (Water)	",
      itemName1: "Mr. Girjesh Tiwari	",
      itemName2: "7311165810",
    },
    {
      id: 5,
      itemName: "Assistant Engineer (Water)	",
      itemName1: "Mr. Jai Kumar	",
      itemName2: "7311165809",
    },
    {
      id: 6,
      itemName: "Junior Engineer (Water)",
      itemName1: "Ku Anu Jaiswal	",
      itemName2: "7311165835",
    },
    {
      id: 7,
      itemName: "Junior Engineer (Water)",
      itemName1: "Mrs. Shashikala",
      itemName2: "6386204048",
    },
  ];

  const TableItems5 = [
    {
      id: 1,
      itemName: "City ​​Health Officer	",
      itemName1: "Mr. Ram Mani Shukla	",
      itemName2: "9451085935",
    },
    {
      id: 2,
      itemName: "Zonal Sanitary Officer	",
      itemName1: "Mr. Subhash Chandra Tripathi",
      itemName2: "7311165812",
    },
    {
      id: 3,
      itemName: "Sanitary inspector	",
      itemName1: "Mr. Kamal Kumar	",
      itemName2: "7311165806",
    },
    {
      id: 4,
      itemName: "Sanitary inspector	",
      itemName1: "Mr. Devi Prasad Shukla	",
      itemName2: "7311166469",
    },
    {
      id: 5,
      itemName: "Sanitary inspector	",
      itemName1: "Mr. Rakesh Kumar Verma	",
      itemName2: "7311165820",
    },
    {
      id: 6,
      itemName: "Sanitary inspector	",
      itemName1: "Mr. Vijayendra Verma	",
      itemName2: "9410041770",
    },
  ];

  const TableItems6 = [
    {
      id: 1,
      itemName: "Assistant Engineer",
      itemName1: "Mr. Rajpati Yadav",
      itemName2: "7906410046",
    },
  ];

  const TableItems7 = [
    {
      id: 1,
      itemName: "Chief City Auditor	",
      itemName1: "Mr. Ashok Kumar Yadav	",
      itemName2: "7311166462",
    },
    {
      id: 2,
      itemName: "Accounts officer	",
      itemName1: "Mr. Narendra Pratap Singh	",
      itemName2: "7311166460",
    },
    {
      id: 3,
      itemName: "Assistant Accounts Officer	",
      itemName1: "Mr. Rakesh Kumar",
      itemName2: "8933043251",
    },
    {
      id: 4,
      itemName: "Accountant",
      itemName1: "Mr. Achintya",
      itemName2: "9451565256",
    },
    {
      id: 5,
      itemName: "Office superintendent",
      itemName1: "Mr. Dinesh Kumar	",
      itemName2: "9415115842",
    },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Contact Us" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Contact Us"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Contact Us
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Name</th>
                      <th scope="col">Contact</th>

                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                         
                        </td>
                        <td>
                            {e.itemName1}
                        </td>
                        <td>
                            {e.itemName2}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </div>
              <div className="card-body aboutus">

              <h5 className="card-title"> About the Mission:</h5>
              <br/><h5><strong>Civil Department</strong></h5>
              <div className="card-body aboutus d-flex justify-content-center">
     <table className="table theadebg table-hover table-striped table-bordered">
       <thead>
         <tr>
           <th scope="col">S.No</th>
           <th scope="col">Designation</th>
           <th scope="col">Name</th>
           <th scope="col">Contact</th>
         </tr>
       </thead>
       <tbody>
         {TableItems2.map((e) => (
           <tr key={e.id}>
             <th scope="row">{e.id}</th>
             <td>
               {e.itemName}
              
             </td>
             <td>
                 {e.itemName1}
             </td>
             <td>
                 {e.itemName2}
             </td>
           </tr>
         ))}
       </tbody>
     </table>
     
   </div>

   <br/><h5><strong>Tax Department</strong></h5>
           <div className="card-body aboutus d-flex justify-content-center">
  <table className="table theadebg table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Designation</th>
        <th scope="col">Name</th>
        <th scope="col">Contact</th>
      </tr>
    </thead>
    <tbody>
      {TableItems3.map((e) => (
        <tr key={e.id}>
          <th scope="row">{e.id}</th>
          <td>
            {e.itemName}
           
          </td>
          <td>
              {e.itemName1}
          </td>
          <td>
              {e.itemName2}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  
</div>

<br/><h5><strong>Water Department</strong></h5>
           <div className="card-body aboutus d-flex justify-content-center">
  <table className="table theadebg table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Designation</th>
        <th scope="col">Name</th>
        <th scope="col">Contact</th>
      </tr>
    </thead>
    <tbody>
      {TableItems4.map((e) => (
        <tr key={e.id}>
          <th scope="row">{e.id}</th>
          <td>
            {e.itemName}
           
          </td>
          <td>
              {e.itemName1}
          </td>
          <td>
              {e.itemName2}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  
</div>

<br/><h5><strong>Health Department</strong></h5>
           <div className="card-body aboutus d-flex justify-content-center">
  <table className="table theadebg table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Designation</th>
        <th scope="col">Name</th>
        <th scope="col">Contact</th>
      </tr>
    </thead>
    <tbody>
      {TableItems5.map((e) => (
        <tr key={e.id}>
          <th scope="row">{e.id}</th>
          <td>
            {e.itemName}
           
          </td>
          <td>
              {e.itemName1}
          </td>
          <td>
              {e.itemName2}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  
</div>

<br/><h5><strong>Light Department</strong></h5>
           <div className="card-body aboutus d-flex justify-content-center">
  <table className="table theadebg table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Designation</th>
        <th scope="col">Name</th>
        <th scope="col">Contact</th>
      </tr>
    </thead>
    <tbody>
      {TableItems6.map((e) => (
        <tr key={e.id}>
          <th scope="row">{e.id}</th>
          <td>
            {e.itemName}
           
          </td>
          <td>
              {e.itemName1}
          </td>
          <td>
              {e.itemName2}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  
</div>

<br/><h5><strong>Accounts Department</strong></h5>
           <div className="card-body aboutus d-flex justify-content-center">
  <table className="table theadebg table-hover table-striped table-bordered">
    <thead>
      <tr>
        <th scope="col">S.No</th>
        <th scope="col">Designation</th>
        <th scope="col">Name</th>
        <th scope="col">Contact</th>
      </tr>
    </thead>
    <tbody>
      {TableItems7.map((e) => (
        <tr key={e.id}>
          <th scope="row">{e.id}</th>
          <td>
            {e.itemName}
           
          </td>
          <td>
              {e.itemName1}
          </td>
          <td>
              {e.itemName2}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  
</div>
              </div>

              </div>

          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Contactuslist;
