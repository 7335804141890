import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import { Link } from "react-router-dom";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Sanitation = () => {
  const TableItems = [
    {
      id: 1,
      itemName: "Door to Door Collection Assesment by CSI",
      itemName1: "View More",
    },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="SANITATION & CLEANERS" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="SANITATION & CLEANERS"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                SANITATION & CLEANERS
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                          <br />
                          <small>
                            <i className="fa-regular fa-file-pdf"></i> |
                            <b>Size:</b> 164 KB | <b>Lang:</b> Hindi |
                            <b>Uploading Date:</b> 10/05/21
                          </small>
                        </td>
                        <td>
                          {" "}
                          <Link to="#" className="btn btn-warning">
                            {e.itemName1}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>

              <p>Sanitation is an important aspect of any city/town for maintenance of proper public health in the city/town. Therefore, Nagar Nigam is also responsible for maintaining proper cleaning and sanitation in its municipal area by continually removing and dumping any kind of solid waste that is generated within its municipal area. For this purpose, Nagar Nigam is involved in sweeping streets, in collecting solid waste from households and collection points and in transporting all these waste collected to the dumping yard. Certainly, Nagar Nigam is responsible for maintenance of cleaned and sanitized environment to all citizens of its municipal area ensuring prevention of diseases and epidemics.

</p>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Sanitation;
