import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CardSlider.css";
import BrandImgItem from "./BrandImgItem";
import Heading from "./Heading";

const BrandSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Array
  const productdata = [
    {
      id: 1,
      imageurl: "../BrandSlider/1.png",
    },
    {
      id: 2,
      imageurl: "../BrandSlider/2.png",
    },
    {
      id: 3,
      imageurl: "../BrandSlider/3.png",
    },
    {
      id: 4,
      imageurl: "../BrandSlider/4.png",
    },
    {
      id: 5,
      imageurl: "../BrandSlider/5.png",
    },
    {
      id: 4,
      imageurl: "../BrandSlider/1.png",
    },
    {
      id: 5,
      imageurl: "../BrandSlider/2.png",
    },
  ];

  const cardproduct = productdata.map((item) => (
    <BrandImgItem
      key={item}
      url={item.imageurl}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      customTransition="all .5"
      transitionDuration={500}
      
    />
  ));

  return (
    <>
      <div className="container-fluid text-center bgcard1">
        <Heading title="Other links" HName="Important Website" />
        <Carousel className="pt-4" responsive={responsive}>{cardproduct}</Carousel>
      </div>
    </>
  );
};

export default BrandSlider;
