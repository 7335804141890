
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const AdvertisementTax = () => {
  const ImportItems = [
    { id: 1, itemName: "Issuance of Advertisement permits to the advertisers after collecting necessary registration fee." },
    { id: 2, itemName: "Collection of renewal charges in case of renew of the advertising permits already given." },
    { id: 3, itemName: "Issuance of licenses for erecting, fixing, retaining, hanging or displaying of advertisements." },
    { id: 4, itemName: "Collection of advertisement taxes." },
    { id: 5, itemName: "Collection of permission fee in case the land used for advertisements belongs to Municipalities." },
    { id: 6, itemName: "Collection of advertisement tax in case of renewals." },
    { id: 7, itemName: "Leasing out identified places for hoarding to display in the municipal places and as well as private sites within the municipality." },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Advertisement Tax" />
          <PageHeaderImg BreadName1="Home" BreadName2="Advertisement Tax" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Advertisement Tax</div>
              <div className="card-body aboutus">
              <p>Advertisement Tax is a tax collected from the Advertiser/ Agency by local bodies for displaying any kind of advertisement like hoardings, wall paintings, balloons, slides and many more within the Local Body’s limit. The revenue department of Local bodies are responsible for administering the Advertisement Tax process that includes:</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default AdvertisementTax;