
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const CitizenCharter = () => {
  const ImportItems = [
    { id: 1, itemName: "To advertise the different works carried out by Nagar Nigam on large scale." },
    { id: 2, itemName: "To ensure the quality of services provided." },
    { id: 3, itemName: "Influential solutions of the public complaints." },
    { id: 4, itemName: "To ensure the replies and actions as per the standards in relation with the public services." },
    { id: 5, itemName: "To maintain transparency at every level." },
    { id: 6, itemName: "To regularize the public services." },
    { id: 7, itemName: "To generate awareness for public participation for public services." },

  ];
  const ImportItems2 = [
    { id: 1, itemName: "To give an explanation for not taking any action after complaints." },
    { id: 2, itemName: "Warning if the explanation is not found to be satisfactory." },
    { id: 3, itemName: "Strict warning if the appropriate action is not taken after 6 complaints." },
    { id: 4, itemName: "Departmental action on not taking any action after 6 complaints." },

  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Citizen Charter" />
          <PageHeaderImg BreadName1="Home" BreadName2="Citizen Charter" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Citizen Charter</div>
              <div className="card-body aboutus">
              <p> According to U.P. Nagar Nigam Act 1959, Nagar Nigam has been handed over the responsibility to provide certain public services to the citizens. As per the 74th Amendment, there has been a considerable increase in the responsibilities of the local bodies. Thus it's the responsibility of these bodies to include; time bound programmes for providing the fundamental public services to the citizens, in the Citizen Charter.</p>
              <p>A Citizen Charter is being presented by Nagar Nigam under which the information regarding time bound proceedings related to street lighting, transportation, sanitation, road maintenance, water clearance, recovery of taxes and fees, veterinary services, release of dead bodies of dead animals etc. will be provided to the citizens.</p>
              <p>This Citizen Charter is being presented, keeping in view the following aims and objectives</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <h5 className="card-title"> Our Resolution:</h5>
                <p>Continuous Services.</p>
                <h5 className="card-title"> Our Determination:</h5>
                <p>High grade quality assurance.</p>
                <p>Following action will be taken on not being prompt in taking appropriate steps to solve the Complaints done by the citizens.</p>
                <ul>
                 {ImportItems2.map((e) => (
                   <li key={e.id}>
                     <i className="fa-solid fa-angles-right" /> {e.itemName}
                   </li>
                 ))}
               </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default CitizenCharter;