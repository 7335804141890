import React from 'react'
import GalleryCardSlider from './GalleryCardSlider';
import GalleryNewsItem from './GalleryNewsItem';

const GalleryNews = ({NewsHeading}) => {

  return (
    <>
      <div className="card mt-4">
        <div className="card-header text-center Annbgcolor">{NewsHeading}</div>
        <GalleryCardSlider />

        <GalleryNewsItem />
      </div>
    </>
  );
}

export default GalleryNews