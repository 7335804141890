import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";

const TownPlanning = () => {
  const ImportItems = [
    { id: 1, itemName: "Development Plan, Physical Planning, Strategic Planning" },
    { id: 2, itemName: "Micro Level Town Planning Schemes" },

  ];

    const ImportItems1 = [
      { id: 1, itemName: "Public Purpose" },
      { id: 2, itemName: "Road Widening" },
      { id: 3, itemName: "Betterment Charges" },
      { id: 4, itemName: "Rehabilitation" },
      
    ];

    const ImportItems2 = [
        { id: 1, itemName: "Disposal of Land and Estate" },
        { id: 2, itemName: "Development of Land" },
        { id: 3, itemName: "Allotment of Housing, Shops & Offices" },
        { id: 4, itemName: "Management of Pay and Use (Parking, etc.)" },
        { id: 5, itemName: "Advertisement Rights" },

      ];

      const ImportItems3 = [
        { id: 1, itemName: "Disposal of Land and Estate" },
        { id: 2, itemName: "Control of Hoardings" },
        { id: 3, itemName: "Encroachment Removal" },
        { id: 4, itemName: "Collection of Fees & Charges" },
        { id: 5, itemName: "Hawking Zone" },

      ];

      const ImportItems4 = [
        { id: 1, itemName: "Integrated Road Design" },
        { id: 2, itemName: "Building Design" },
        
      ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Town Planning Department" />
          <PageHeaderImg BreadName1="Home" BreadName2="Town Planning Department" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Town Planning Department</div>
              <div className="card-body aboutus">
                <p><strong>Functions of the Town Planning Department :-</strong></p>
                <p><strong>1.Spatial Planning of Municipal Corporation</strong></p>

                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p>2. Acquisitions</p>

                <ul>
                  {ImportItems1.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

                <p>3. Alignment - Road Widening</p>
                 <ul>
                   {ImportItems2.map((e) => (
                     <li key={e.id}>
                       <i className="fa-solid fa-angles-right" /> {e.itemName}
                     </li>
                   ))}
                 </ul>
               
                 <p>4. Development Control</p>
                 <ul>
                   {ImportItems3.map((e) => (
                     <li key={e.id}>
                       <i className="fa-solid fa-angles-right" /> {e.itemName}
                     </li>
                   ))}
                 </ul>

 <p>5. Special Projects</p>
 <ul>
   {ImportItems4.map((e) => (
     <li key={e.id}>
       <i className="fa-solid fa-angles-right" /> {e.itemName}
     </li>
   ))}
 </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
};

export default TownPlanning;
