import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Recordsofbirths = () => {
  const ImportItems = [
    { id: 1, itemName: "Register Birth/death in its municipal area." },
    { id: 2, itemName: "Maintain proper record of each Birth/Death taking place in its municipal area." },
    { id: 3, itemName: "Provide this record of Birth/Deaths in their municipal area to government whenever required." },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="RECORDS OF BIRTHS AND DEATHS" />
          <PageHeaderImg BreadName1="Home" BreadName2="RECORDS OF BIRTHS AND DEATHS" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">RECORDS OF BIRTHS AND DEATHS</div>
              <div className="card-body aboutus">
              <p>
              Birth/Death Registration is not only an essential document for individuals as a proof of their official existence and as an official document required for various purposes, but it is also important for government for various purposes. Its major purpose is compilation of accurate, complete and timely vital statistics, which, along with population censuses, are central to estimating population size – especially for small areas. Apparently, as soon a birth or death happens in an area, related individuals approach its municipal area for birth and death registration whose record is maintained by the Nagar Nigam of that area. Certainly, the major functions of Nagar Nigam in terms of Birth/Death registration record maintenance are:
</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Recordsofbirths;
