import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import { Link } from 'react-router-dom';
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";
const Tax = () => {
    const TableItems = [
      {
        id: 1,
        itemName: "Tax and Non Tax",
        itemName1:"View More",
      },
   
    ];
    const ImportItems = [
      { id: 1, itemName: "Preparation of demand registers for taxes, inviting objections, levy and collection of tax from all properties coming under municipal area." },
      { id: 2, itemName: "In case of non-payment of taxes, confiscate and auction the property coming under local bodies' municipal area." },
      { id: 3, itemName: "Collection of water charges from citizens of their municipal area." },
      { id: 4, itemName: "Imposing and collection of Advertisement Tax, wherever needed." },
      { id: 5, itemName: "Assessment and collection of tax from new properties."},
      { id: 6, itemName: "Collection of License Tax as per the rules." },
      { id: 7, itemName: "Levy and collection of vacant land and agricultural land tax." },
      { id: 8, itemName: "Levy and collection of taxes pertaining to employment guarantee cess and education cess." },
      { id: 9, itemName: "Levy and Collection of property tax from property owners/property holders." },
      { id: 10, itemName: "Levy and collection of water charges within its Municipal area."},
    ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Tax Department" />
          <PageHeaderImg BreadName1="Home" BreadName2="Tax Department" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Tax Department</div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>{e.itemName}
                            <br />
                        <small>
                          <i className="fa-regular fa-file-pdf"></i> |
                          <b>Size:</b> 164 KB | <b>Lang:</b> Hindi |
                          <b>Uploading Date:</b> 10/05/21
                        </small>
                        </td>
                        <td>  <Link to="#" className="btn btn-warning">
                          {e.itemName1}
                        </Link></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="card-body aboutus">

              <p>
               <strong>
                 Besides the above, following are the detailed role &
                 function of Nagar Nigam Ayodhya is given:
               </strong>
             </p>
                   <ul>
                     {ImportItems.map((e) => (
                       <li key={e.id}>
                         <i className="fa-solid fa-angles-right" /> {e.itemName}
                       </li>
                     ))}
                   </ul>
                 </div>
            </div>
            </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
};

export default Tax;
