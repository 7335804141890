import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import Footer from "../other/Footer";
import FooterPre from "../other/FooterPre";

const Municipalactivitiesprofile = () => {
  const ImportItems = [
    { id: 1, itemName: "Cleaning of public streets and drains" },
    { id: 2, itemName: "Cleaning of public toilets and urinals" },
    { id: 3, itemName: "Repair and maintenance of drains" },
    { id: 4, itemName: "Disposal of animal (dogs) carcasses" },
    { id: 5, itemName: "Measures to check the spread of infectious diseases by checking sale of dirty/adulterated food material (fruits, drinks etc)" },
    { id: 6, itemName: "Controlling the enterprise / professionals which are harmful for public health" },
    { id: 7, itemName: "Removal of debris" },
    { id: 8, itemName: "Solid waste management" },
    { id: 9, itemName: "Works related to rubbish removal" },
    { id: 10, itemName: "Catching stray animals (dogs etc)" },
    { id: 11, itemName: "Maintenance of parks" },
    { id: 12, itemName: "Protection of green trees in public places" },
    { id: 13, itemName: "Disposal of dead and dry trees" },
 
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Municipal Activities Profile" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Municipal Activities Profile"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Municipal Activities Profile
              </div>
              <div className="card-body aboutus">
                <h5 className="card-title"> Activities Profile</h5>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre />
    </>
  );
};

export default Municipalactivitiesprofile;
