import React from "react";
import logonagar from "../../assets/sarkar.png";
import Ayodhyalogo from "../../assets/Ayodhyalogo.png";
import logonagarnigam from "../../assets/logonagar.png";

const MiddleHeader2 = () => {
  return (
    <>
      <div className="container-fluid text-center middleheader1">
        <div className="container">
          <div className="row">
            <div className="imagestylediv col-lg-1">
              <img
                className="img img-responsive"
                src={logonagarnigam}
                alt="Ayodhya Nagar Nigam logo"
              />
            </div>
            <div className="col-lg-7 Middleitem1 imagestylediv d-none d-sm-block">
              <span className="hdngmiddle">Nagar Nigam Ayodhya</span>
              <p className="secondHeading">Ayodhya, Uttar Pradesh</p>
            </div>
            <div className="imagestylediv col-lg-1">
               <img
                 className="img img-responsive"
                 src={logonagar}
                 alt="Ayodhya Nagar Nigam logo"
                 style={{ marginLeft: '15px' }}
               />
             </div>
            <div className="col d-none d-sm-block">
              <img className="img" src={Ayodhyalogo} alt="Ayodhya Nagar Nigam logo" />
            </div>
            <div className="col-lg-1 Middleitem">
              <button type="button" className="btn btncolor">
                <i className="fa-solid fa-right-to-bracket"></i> Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleHeader2;
