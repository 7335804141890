import React from 'react'
import { Link } from 'react-router-dom';

const AlertsAnn = ({ heading }) => {
  const items = [
    {
      id: 1,
      name: "",
    },
    {
      id: 2,
      name: "",
    },
    {
      id: 3,
      name: "",
    },
    {
      id: 4,
      name: "",
    },
  ];
  return (
    <>
      <div className="card mb-3">
        <h5 className="card-header bg-warning">{heading}</h5>
        <div className="card-body text-lg-start alertnews">
          <marquee direction="up" max-height="200px" scrollamount="2">
            <ul className="card-text">
              {items.map((item) => (
                <Link key={item.id} to="#" className="annusment">
                  {/* <li> */}
                    {/* {item.name} */}
                    {/* <i className="fa-solid fa-chevron-right"></i> */}
                  {/* </li> */}
                </Link>
              ))}
            </ul>
          </marquee>
        </div>
      </div>
    </>
  );
};

export default AlertsAnn