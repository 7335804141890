import React from 'react'

const CardSliderProduct = (props) => {
  return (
    <div className="container-fluid d-flex align-items-center bgcard">
      <div className="container">
        <div className="card">
          <img src={props.url} className="card-img-top imgcards" alt="..." />
          <div className="card-body">
            <h5 className="card-title">{props.heading}</h5>
            <p className="card-text modernWay">{props.dic}</p>
            <a href="#" className="btn btn-warning">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSliderProduct