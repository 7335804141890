
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Map = () => {
 
 
 
 
 

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Map" />
          <PageHeaderImg BreadName1="Home" BreadName2="Map" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Map</div>
              <div className="card-body aboutus">
             
              <iframe width="100%" height="800" src="./../Footer/Ayodhya-map.pdf" data-ruffle-polyfilled=""></iframe>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default Map;