import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";

const Record = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Record Department" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Record Department"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Record Department
              </div>
              <div className="card-body aboutus d-flex">
                <p>
                Record Department of Ayodhya Nagar Nigam is working under highly secured and safe working system. Ayodhya Nagar Nigam mainly maintains all old records of citizens, such as birth and death registration, sanctioned building plans, assessment registers, etc. One can get his/her record registered here or may recover it in case he lost it.


                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre/>
    </>
  );
};

export default Record;
