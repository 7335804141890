import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Issuingtrade = () => {


  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="ISSUING TRADE LICENSE" />
          <PageHeaderImg BreadName1="Home" BreadName2="ISSUING TRADE LICENSE" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">ISSUING TRADE LICENSE</div>
              <div className="card-body aboutus">
              <p>For any individual to carry out its trade or business in any area, he/she requires issuance of the trade license by Nagar Nigam of that area. Apparently, the trade license issued by Nagar Nigam gives permission to an individual to carry out trade or business for which license is issued. However, it does not grant ownership of the property where trade is conducted but only allow trade related activities to occur. Types of trade licenses that are issued:</p>
                <h5 className="card-title">Type A</h5>
                <p>It involves all eating establishments like Hotels, Restaurants , Refreshments , Sale of Coffee and Tea , Lodging Houses , Bakeries , Sweet meat stall , Sale of Mutton , Fish & Chicken and Provision stores.</p>
                <h5 className="card-title">Type B</h5>
                <p>It involves all trades which use motive power to run and to manufacture like, Industries, Factories, Workshops, Power rooms, Flour Mills, Cyber cafe etc.</p>
                <h5 className="card-title">Type C</h5>
                <p>It involves Offensive and dangerous trades like, charcoal depot , sale of firewood, timber wood, dry clean shops , Dhobi shops , Beauty parlour , Barbershop , Sale of edible oil, storage of ammunitions ,breweries, manufacture camphor and candles etc.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Issuingtrade;
