import React from 'react'
import { Link } from 'react-router-dom';


const ImportLinks = () => {
  const ImportItems = [
    { id: 1, itemName: "News Letter", icon: "fa-solid fa-file-lines", url: "/newsletter", target: "_blank" },
    { id: 2, itemName: "Balance Sheet", icon: "fa-solid fa-file-lines", url: "/balanceSheet"  },
    { id: 3, itemName: "Local Body Directorate", icon: "fa-solid fa-thumbtack", url: "http://localbodies.up.nic.in/go/go_index.htm"  },
    { id: 4, itemName: "Government Orders", icon: "fa-solid fa-layer-group", url: "/government-Orders"  },
    { id: 5, itemName: "Acts, Rules and Manuals", icon: "fa-solid fa-pen-to-square", url: "/acts-Rules-Manuals"  },
    { id: 6, itemName: "Citizen Charter", icon: "fa-solid fa-user", url: "/Citizen-Charter"  },
    { id: 7, itemName: "Feedback", icon: "fa-solid fa-comment", url: "/feedback"  },
    { id: 8, itemName: "Report", icon: "fa-solid fa-briefcase", url: "/report"  },
    { id: 9, itemName: "Information Desk", icon: "fa-solid fa-link", url: "/Information-Desk"  },
    { id: 10, itemName: "Map", icon: "fa-solid fa-map", url: "/Map"  },
    
  ];

  return (
    <div>
      <div className="list-group pb-4">
        <div
          className="list-group-item list-group-item-action bg-warning card-title h5"
          aria-current="true"
        >
          <i className="fa-solid fa-link"></i> Important Links
        </div>
        <div className="overflow-auto">
          {ImportItems.map((e) => (

            <div key={e.id} className="list-group-item list-group-item-action list-group-item-warning">
              <i className={`${e.icon} pe-2`}></i>
              <Link target='blank' to={e.url}>{e.itemName}</Link>
            </div>

            // <div key={e.id} className="list-group-item list-group-item-action list-group-item-warning">
            /* <i className={`${e.icon} pe-2`}></i> */
            /* {e.url=='../Footer/e-newsletter.pdf'? */
                        // <Link target='_blank' to={e.url}>{e.itemName}</Link>:
                        // <Link  to={e.url}>{e.itemName}</Link>
            // }
            /* </div> */
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImportLinks