
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const PropertyTax = () => {
  const ImportItems = [
    { id: 1, itemName: "Tax shall be assessed on the basis of present circle rated of property or land, size of property and the type of construction made." },
    { id: 2, itemName: "The Tax assessment process will be well planned & well organized and convenient and will be enforced in all modern cities." },
    { id: 3, itemName: "Strict Action and penalties will be imposed on hiding any facts or on wrong assessment of tax." },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Property Tax" />
          <PageHeaderImg BreadName1="Home" BreadName2="Property Tax" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Property Tax</div>
              <div className="card-body aboutus">
              <p>In order to strengthen the economic condition of Nagar Nigam of Uttar Pradesh and to make them independent, 74th constitutional ammendment has been made and as per section-9 of Nagar Vikas & order no.- 1435/Nine-9-2000-63J/95 T.C. dated 22 April 2000, an Uttar Pradesh Nagar Nigam (Property Tax) Rules Manual' 2000 has been forwarded. These measures are being taken to bring authenticity and transparency in assessment of Property Tax and to make the Self Tax Assessment System available to all the citizens.</p>
              <p>The different processes / proceedings of this system are being advertised on a great scale by Nagar Nigam. It’s proposed to put up hoardings at different places for this purpose.</p>
              <p>Self-Tax assessment is in favour of both citizens and Palika. It is based on logical and authentic standards. It is not biased and is transparent and reasonable. The tax assessment shall be done on the basis of site map and details submitted in the Nagar Nigam. Under this self-tax assessment system, a person has been authorized to assess his own tax on the basis of the details of his buildings. Different kinds of pre-defined forms have also been made available for the citizens as per the instructions given by the government. If the site map or the details are not present/ available, the tax assessment will be done by the officers.</p>
              <p>The main objective of this Self-Tax Assessment system is to make the following facilities available to the citizens</p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p>This self-tax assessment system will take comparatively less time than present Tax System. Nobody could be able to misguide any citizens for personal/selfish motives. This system will benefit all the citizens and will also be helpful in making all the bodies of Uttar Pradesh, economically strong.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default PropertyTax;