import React from 'react'
import FooterPre from '../other/FooterPre';
import Footer from '../other/Footer';

const RightToInformation = () => {
  return (
    <div>
      RightToInformation
      <Footer />
      <FooterPre />
    </div>
  );
}

export default RightToInformation