import React from "react";
import { Link } from "react-router-dom";

const LeadersTeam = (props) => {
  return (
    <>
      <div className="col mb-3 mb-sm-0 px-2">
        <div className="card">
          <img src={props.url} className="card-img-top Leaderimgbg" alt="..." />
          <div className="card-body">
            <h5 className="card-title teamHeading">{props.name}</h5>
            <p className="card-text">{props.dic}.</p>
            <span>
              <Link to={props.facebook} target="_blank">
                <i className="fa-brands fa-facebook socialicon" />
              </Link>
              <Link to={props.twitter} target="_blank">
                <i className="fa-brands fa-twitter socialicon" />
              </Link>
              <Link to={props.instagram} target="_blank">
                <i className="fa-brands fa-instagram socialicon" />
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadersTeam;
