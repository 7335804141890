
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const AyodhyaCity = () => {
  const ImportItems = [
    { id: 1, itemName: "" },
    { id: 2, itemName: "" },
    { id: 3, itemName: "" },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="About Ayodhya City" />
          <PageHeaderImg BreadName1="Home" BreadName2="About Ayodhya City" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">About Ayodhya City</div>
              <div className="card-body aboutus">
              <p>Ayodhya, located in the northern Indian state of Uttar Pradesh, is one of the most revered cities in India, with a rich historical, cultural, and religious heritage. Here's an overview of Ayodhya:</p>
      
              <h5 className="card-title"> Historical Significance</h5>
              <ul>
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Ancient History:</strong>Ayodhya is one of the oldest cities in India, with references in ancient texts like the Ramayana, where it is described as the birthplace of Lord Rama.
                  </li>                
                  <li>
                    <i className="fa-solid fa-angles-right" /> <strong>Religious Importance:</strong>It is considered a sacred city by Hindus, Jains, and Buddhists. The city has been an important pilgrimage site for centuries.
                  </li>                
              </ul>

              <h5 className="card-title"> Cultural Significance</h5>
              <ul>
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Festivals:</strong>Ayodhya is known for its vibrant festivals, especially Diwali, which is celebrated with grandeur as it marks Lord Rama's return to the city after defeating Ravana.
                  </li>                
                  <li>
                    <i className="fa-solid fa-angles-right" /> <strong>Architecture:</strong>The city is home to numerous temples and historical monuments that showcase traditional Indian architecture.
                  </li>                
              </ul>

              <h5 className="card-title"> Religious Landmarks</h5>
              <ul>
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Ram Janmabhoomi:</strong>Believed to be the birthplace of Lord Rama, this site has been a focal point for religious and historical discussions.
                  </li>                
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Hanuman Garhi:</strong>A popular temple dedicated to Lord Hanuman, known for its unique architecture and spiritual significance.
                  </li>     
                  <li>
                   <i className="fa-solid fa-angles-right" /><strong>Kanak Bhawan: </strong>A temple that is said to have been gifted to Sita by Queen Kaikeyi, featuring beautiful idols of Rama and Sita.
                 </li>                
                 <li>
                   <i className="fa-solid fa-angles-right" /> <strong>Nageshwarnath Temple: </strong>An ancient temple dedicated to Lord Shiva, believed to have been built by Kush, the son of Lord Rama.
                 </li>                           
                  </ul>

              <h5 className="card-title"> Modern Ayodhya</h5>
              <ul>
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Development: </strong>In recent years, Ayodhya has seen significant development, with efforts to improve infrastructure, tourism facilities, and civic amenities.
                  </li>                
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Tourism: </strong> The city attracts millions of tourists and pilgrims annually, contributing to the local economy and cultural exchange.
                  </li>                
              </ul>

              <h5 className="card-title"> Administration</h5>
              <ul>
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Governance: </strong>Ayodhya is governed by the Ayodhya Nagar Nigam (Municipal Corporation), which is responsible for the city's civic administration and development projects.
                  </li>                
                  <li>
                    <i className="fa-solid fa-angles-right" /><strong>Connectivity: </strong> Ayodhya is well-connected by road and rail, with plans for further improvements to facilitate easier access for tourists and pilgrims.
                  </li>                
              </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default AyodhyaCity;