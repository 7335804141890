import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import { Link } from "react-router-dom";
import Footer from './../../other/Footer';
import FooterPre from './../../other/FooterPre';
const Health = () => {
  const TableItems = [
    {
      id: 1,
      itemName: "ODF Declaration Paper Clip",
      itemName1: "View More",
    },
    {
      id: 2,
      itemName: "ODF Self Declaration by Mayor",
      itemName1: "View More",
    },
    {
      id: 3,
      itemName: "ODF Waste Segrigation Status",
      itemName1: "View More",
    },
  ];
  const ImportItems = [
    { id: 1, itemName: "Cleaning of public streets, roads and drains." },
    {
      id: 2,
      itemName:
        "Banning the sale of expired/rotten and adulterated food material.",
    },
    {
      id: 3,
      itemName:
        "Utilizing effective preventive and control measures against contagious/ infectious diseases.",
    },
    {
      id: 4,
      itemName: "Issuing licenses under Food Adulteration Impediment Act.",
    },
    {
      id: 5,
      itemName: "Controlling the places allotted for disposal of dead bodies.",
    },
    {
      id: 6,
      itemName:
        "Supply of clean drinking water in parks and water supply for commercial purpose.",
    },
    { id: 7, itemName: "Cleaning of drainage." },
    {
      id: 8,
      itemName:
        "To clear sewerage from domestic and industrial establishments of the city to the treatment plants, treating it and then disposing it appropriately.",
    },
    {
      id: 9,
      itemName:
        "To construct efficient and smooth sewage system in the area and its maintenance.",
    },
    {
      id: 10,
      itemName:
        "Another major responsibility is of Solid Waste Management, for maintaining proper cleanliness and health environment in an area.",
    },
    {
      id: 11,
      itemName:
        "Collecting, treating and disposing of solid material that is discarded because it has served its purpose or is no longer useful.",
    },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Health Department" />
          <PageHeaderImg BreadName1="Home" BreadName2="Health Department" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Health Department</div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                          <br />
                          <small>
                            <i className="fa-regular fa-file-pdf"></i> |
                            <b>Size:</b> 164 KB | <b>Lang:</b> Hindi |
                            <b>Uploading Date:</b> 10/05/21
                          </small>
                        </td>
                        <td>
                          {" "}
                          <Link to="#" className="btn btn-warning">
                            {e.itemName1}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="card-body aboutus">
                <p>
                  Public Health Department of the Nagar Nigam Ayodhya is solely
                  responsible for providing clean and healthy environment to its
                  citizen for maintaining proper health and sanitation
                  conditions within their municipal area.
                </p>
                <p>
                  <strong>
                    Key responsibilities of Public Health/ Sanitation Department
                    are:
                  </strong>
                </p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre />
    </>
  );
};

export default Health;
