import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';
import './PhotoGallery.css'; // Import the CSS file for styling


const PhotoGallery = () => {
    const images = [
        { src: '../../Gallery/11.jpg', title: 'Clean Survey 2018 in various schools of Ayodhya Nagar Nigam' },
        { src: '../../Gallery/12.jpg', title: 'Clean Survey 2018 in various schools of Ayodhya Nagar Nigam' },
        { src: '../../Gallery/13.jpg', title: 'Clean Survey 2018 in various schools of Ayodhya Nagar Nigam' },
        { src: '../../Gallery/14.jpg', title: 'Clean Survey 2018 in various schools of Ayodhya Nagar Nigam' },
        { src: '../../Gallery/14.jpg', title: 'Night sweeping Naya Ghat Ayodhya' },
        { src: '../../Gallery/15.jpg', title: 'Night sweeping Naya Ghat Ayodhya' },
        { src: '../../Gallery/16.jpg', title: 'Night sweeping Naya Ghat Ayodhya' },
        { src: '../../Gallery/17.jpg', title: 'Surprise inspection by Municipal Commissioner' },
      ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Photo Gallery" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Photo Gallery"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
              Photo Gallery
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
              <div className="image-grid">
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image.src} alt={image.title} className="image" />
                  <div className="overlay">
                    <div className="text">{image.title}</div>
                  </div>
                </div>
              ))}
            </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default PhotoGallery;
