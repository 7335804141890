import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import FooterPre from "../other/FooterPre";
import Footer from "../other/Footer";

const Executiveofficer = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Executive Officer’s Message" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Executive Officer’s Message"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Executive Officer’s Message
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
                <ul>
                  <li>Content will be available soon...</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre />
    </>
  );
};

export default Executiveofficer;
