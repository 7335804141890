import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import Footer from "../other/Footer";
import FooterPre from "../other/FooterPre";

const SourcesFunds = () => {
  const ImportItems = [
    { id: 1, itemName: "Property Tax" },
    { id: 2, itemName: "Sewerage Tax" },
    { id: 3, itemName: "Conservancy Tax" },
    { id: 4, itemName: "Water Tax and water charges" },
  ];

    const ImportItems1 = [
      { id: 1, itemName: "Trade Licenses (including entertainment and vehicles)" },
      { id: 2, itemName: "Birth-death registration and Certificates issue" },
      { id: 3, itemName: "Community bookings of the ULB properties" },
      { id: 4, itemName: "Lease and rent of ULB Properties" },
      { id: 5, itemName: "Tehbazari, parking charges, license of pets (dogs etc)" },
      { id: 6, itemName: "License fee on advertising/publicity services in public places (kiosks, hoardings, advertising screens, glowsigns, posters and banners etc)" },
      { id: 7, itemName: "Rent from rental properties" },
      { id: 8, itemName: "Part stamp duty collection" },
      
    ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Sources of Funds" />
          <PageHeaderImg BreadName1="Home" BreadName2="Sources of Funds" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Sources of Funds</div>
              <div className="card-body aboutus">
                <p>Its sources of income are as follows:</p>
                <h5 className="card-title"> Tax revenues</h5>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

                <h5 className="card-title"> Non-Tax revenues</h5>
                <ul>
                  {ImportItems1.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p>Grants from the State Government.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre />
    </>
  );
};

export default SourcesFunds;
