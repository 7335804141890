
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const LocalBodiesDirectorate = () => {
  const ImportItems = [
    { id: 1, itemName: "Delhi to Ayodhya: Approximately 636 km." },
    { id: 2, itemName: "Mumbai to Ayodhya: Approximately 1575 km." },
    { id: 3, itemName: "Kolkata to Ayodhya: Approximately 920 km." },
    { id: 4, itemName: "Chennai to Ayodhya: Approximately 1950 km." },
    { id: 5, itemName: "Bengaluru to Ayodhya: Approximately 1900 km." },
    { id: 6, itemName: "Hyderabad to Ayodhya: Approximately 1300 km." },
    { id: 7, itemName: "Ahmedabad to Ayodhya: Approximately 1350 km." },
    { id: 8, itemName: "Nagpur to Ayodhya: Approximately 805 km." },
    { id: 9, itemName: "Kochi to Ayodhya: Approximately 2450 km." },
    { id: 10, itemName: "Guwahati to Ayodhya: Approximately 1200 km." },
    { id: 11, itemName: "Lucknow to Ayodhya: Approximately 135 km." },
    { id: 12, itemName: "Varanasi to Ayodhya: Approximately 220 km." },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Local Bodies Directorate" />
          <PageHeaderImg BreadName1="Home" BreadName2="Local Bodies Directorate" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Local Bodies Directorate</div>
              <div className="card-body aboutus">
              <h5 className="card-title"> By Air</h5>
              <p><strong>Nearest Airport are:-</strong></p>
              <ul><li>
              <i className="fa-solid fa-angles-right" /><strong>Maharishi Valmiki International Airport, Ayodhya Dham:-</strong>It is an international airport serving the twin cities of Ayodhya and Faizabad in the state of Uttar Pradesh, India. The Maharishi Valmiki International Airport (AYJ) was inaugurated recently with direct flights to Ayodhya from Mumbai, Delhi and Ahmedabad. You can opt for direct or connecting flights depending upon the availability and Ayodhya flight airfare. Distance from Ayodhya Dham – 10 km.
              </li>
              <li>
                <i className="fa-solid fa-angles-right" /> <strong>Chaudhary Charan Singh International Airport:-</strong>It is located in Amausi(Lucknow), 14 km from the city centre. Distance from Ayodhya Dham – 125
              </li>                
              <li>
                <i className="fa-solid fa-angles-right" /> <strong>Mahayogi Gorakhnath Airport:-</strong>Distance from Ayodhya Dham – 140 km.
              </li>                
              </ul>
              <h5 className="card-title"> By Train</h5>
              <p><strong>You can easily get regular trains to Ayodhya from major cities of the country:-</strong></p>
                <ul>
                    <li>
                      <i className="fa-solid fa-angles-right" /> Ayodhya Cant railway station.
                    </li>                
                    <li>
                      <i className="fa-solid fa-angles-right" /> Ayodhya Dham railway station.
                    </li>                
                </ul>

                <h5 className="card-title"> By Bus</h5>
                 <p><strong>Nearest Airport are:-</strong></p>
                   <ul>
                       <li>
                         <i className="fa-solid fa-angles-right" />The roads around Ayodhya are very well-developed. You can find many bus routes that take you to the holy city. Private and public bus services ply the route and take you to all the major destinations around such as Delhi, Lucknow, Allahabad, Gorakhpur and so on and also Uttar Pradesh State Road Transport Corporation (UPSRTC) buses are available 24 hrs a day.
                       </li>                
                   </ul>
                   <p><strong>Distance to Ayodhya from major cities:-</strong></p>

                    <ul>
                       {ImportItems.map((e) => (
                         <li key={e.id}>
                           <i className="fa-solid fa-angles-right" /> {e.itemName}
                         </li>
                       ))}
                     </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default LocalBodiesDirectorate;