import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from './../../other/Footer';
import FooterPre from './../../other/FooterPre';

const Construction = () => {
  const ImportItems = [
    {
      id: 1,
      itemName: "Development permission of high rise, commercial and layouts",
    },
    { id: 2, itemName: "Supervision of high-rise and commercial buildings" },
    { id: 3, itemName: "Permission of hoardings on private premises" },
    {
      id: 4,
      itemName:
        "Registration of Architects / Engineers/Structural designers etc.as per D.C.R.",
    },
    { id: 5, itemName: "Framing policies related to the Department." },
  ];

  const ImportItems1 = [
    {
      id: 1,
      itemName: "Development permission of low-rise non-commercial buildings.",
    },
    {
      id: 2,
      itemName:
        "Control and Supervision of all development other than high-rise and commercial buildings",
    },
    {
      id: 3,
      itemName: "Permission for temporary structures of exhibition, fairs etc.",
    },
    { id: 4, itemName: "Permission for Tele-Communication Towers." },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Construction Department" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Construction Department"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Construction Department
              </div>
              <div className="card-body aboutus">
                <p>
                  The major responsibility of the Nagar Nigam in terms of
                  construction is to construct roads and bridges in their
                  defined area using highest quality of materials and technology
                  enabling proper management of roadways and traffic in their
                  defined jurisdiction area. Certainly, Nagar Nigam works for
                  planning, Design, Implementation & Monitoring of various Road
                  and Bridge Construction work within its jurisdiction. Nagar
                  Nigam Ayodhya is completely dedicated to provide construction
                  services to its citizen for betterment of lifestyle of the
                  citizen.
                </p>
                <h5 className="card-title"> Function</h5>
                <p>Centralized Functions:</p>

                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p>De-centralized Functions:</p>

                <ul>
                  {ImportItems1.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre />
    </>
  );
};

export default Construction;
