import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Digitalindia = () => {
  const ImportItems = [
    { id: 1, itemName: "Digital Infrastructure as a Core Utility to Every Citizen;" },
    { id: 2, itemName: "Governance and Services on Demand; and" },
    { id: 3, itemName: "Digital Empowerment of Citizens" },
  ];
  const ImportItems1 = [
    { id: 4, itemName: "Ministries / Departments / States would fully leverage the Common and Support ICT Infrastructure established by GoI. DeitY would also evolve/ lay down standards and policy guidelines, provide technical and handholding support, undertake capacity building, R&D, etc." },
    { id: 5, itemName: "The existing/ongoing e-Governance initiatives would be suitably revamped to align them with the principles of Digital India. Moreover, to enhance the delivery of government services to citizens’ new methods and approach would be undertaken like Process Reengineering, scope enhancement, use of integrated & interoperable systems and deployment of emerging technologies like cloud & mobile etc." },
    { id: 6, itemName: "Flexibility would be given to the State to identify for inclusion additional state-specific projects, which are relevant for their socio-economic needs." },
    { id: 7, itemName: "E-Governance would be promoted through centralised initiative to the extent necessary that would ensure citizen centric service orientation, interoperability of various e-Governance applications and optimal utilisation of ICT infrastructure/ resources, while adopting a decentralised implementation model." },
    { id: 8, itemName: "Successes would be identified and their replication would promote along with its customisation whenever it is necessary." },
    { id: 9, itemName: "Public Private Partnership would be preferred whenever necessary for implementation of e-Governance projects but with adequate management and strategic control." },
    { id: 10, itemName: "To facilitate identification, authentication and delivery of benefits, adoption of unique ID would be promoted." },
    { id: 11, itemName: "For faster designing, developing and implementation of the e-Governance projects positions of Chief Information Officers (CIO) would be created in at least 10 key Ministries." },
  ];
  const ImportItems2 = [
    { id: 12, itemName: "Broadband availability for all" },
    { id: 13, itemName: "Universal access to mobile connectivity" },
    { id: 14, itemName: "Public Internet access Programme" },
    { id: 15, itemName: "e-Governance- Transforming government through Technology" },
    { id: 16, itemName: "E-Kranti-electronic delivery of services" },
    { id: 17, itemName: "Information for all" },
    { id: 18, itemName: "Electronic Manufacturing" },
    { id: 19, itemName: "IT for jobs" },
    { id: 20, itemName: "Early Harvest Programmes" },

  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Digital India Program" />
          <PageHeaderImg BreadName1="Home" BreadName2="Digital India Program" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Digital India Program</div>
              <div className="card-body aboutus">
                <h5 className="card-title">Introduction:</h5>
                <p>In mid 1990s e-governance initiative started in India with projects like railway computerization, land record computerization and many more which were mainly emphasized on providing citizen-centric services. But due to limited features, these e-governance services did not have much impact and created the need for a more comprehensive planning and implementation for the infrastructure required to be put in place, interoperability issues to be addressed, etc. to establish a more connected government.
                </p><br />
                <p>Apparently, considering the shortcomings of the e-governance Plan that includes lack of integration amongst Government applications and databases, low degree of government process reengineering, scope for leveraging emerging technologies like mobile, cloud…and many more Government of India Has approved e-Kranti project which has a vision of “Transforming e-Governance for Transforming Governance” The focus is to bring Transformation to Realize
                </p><br />
                <p>IT (Indian talent) + IT (Information Technology) = IT (India Tomorrow)
                </p>
                <h5 className="card-title">Vision:</h5>
                <p>The vision of the Digital India Programme is to transform India into a digitally empowered society and knowledge economy.</p>
           
              <h5 className="card-title">Key Vision Areas:</h5>

                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <h5 className="card-title">Approach and Methodology:</h5>
                <ul>
                  {ImportItems1.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <h5 className="card-title">Pillars of Digital India:</h5>
                <ul>
                  {ImportItems2.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
       <FooterPre/>
      </div>
    </>
  );
};

export default Digitalindia;
