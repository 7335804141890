
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const TermCondition = () => {

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Term & Condition" />
          <PageHeaderImg BreadName1="Home" BreadName2="Term & Condition" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Term & Condition</div>
              <div className="card-body aboutus">
                <p>This website is designed, developed and maintained by Omni-Net Technologies Pvt. Ltd. on behalf of UPDESCO & hosted at Uttar Pradesh State Data Centre, Lucknow.</p>
              <p>Though all efforts have been made to ensure the accuracy of the content on this website, the same should not be construed as a statement of law or used for any legal purposes. In case of any ambiguity or doubts, users are advised to verify/check with the Department(s) and/or other source(s), and to obtain appropriate professional advice.</p>
              <p>Under no circumstances will this Department/UPDESCO/Service Provider be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website. These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of India.</p>
              <p>All of the content and Information brought to you by Nagar Nigam Ayodhya, State Government of Uttar Pradesh, India.</p>
              <p>Please refer following Policies related Privacy, Copyright and Hyperlinking Policy of this Portal, If you need any more information than please visit following or feel free to contact us.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default TermCondition;