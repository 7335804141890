import React from "react";
import PageHeaderImg from "./../../other/PageHeaderImg";
import Heading2 from "./../../other/Heading2";
import Footer from "../../other/Footer";
import FooterPre from './../../other/FooterPre';

const Circulars = () => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
    // Submit Form
  const SubmitForm = async (e) => {
    e.preventDefault();
    // Get Data
    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);
    console.log(payload);

  };
  return (
    <>
      <div>
        <div className="accountBGImg">
          <div className=" container breadcum">
            <Heading2 HName="Circulars" />
            <PageHeaderImg BreadName1="Home" BreadName2="Circulars" />
          </div>
        </div>
        <div className="container-fluid ">
   <div className="row py-4">
     <div className="col-lg-12">
       <div className="card mb-3">
         <div className="card-header h4 bg-warning">
         Circulars
         </div>
        <div className="container py-4 bgsize">
          <div className="row">
            <form
              className="row g-3 needs-validation"
              noValidate
              onSubmit={SubmitForm}
            >
                <div className="col-md-1">
    <label htmlFor="month" className="form-label">
    Pages
    </label>
    <select
      className="form-select"
      id="month"
      name="month"
      required
    >
      <option selected disabled value>
        10
      </option>
      <option>20</option>
      <option>30</option>
      <option>50</option>
      <option>100</option>

    </select>
    <div className="invalid-feedback">
      Please select a valid state.
    </div>
  </div>
              <div className="col-md-3">
                <label htmlFor="search" className="form-label">
                  Circulars No
                </label>
                <input
                  type="search"
                  className="form-control"
                  id="search"
                  name="search"
                  placeholder="Circulars No"
                  required
                />
                <div className="valid-feedback">Looks good!</div>
              </div>
              <div className="col-md-3">
              <label htmlFor="search" className="form-label">
                Circulars Subject
              </label>
              <input
                type="search"
                className="form-control"
                id="search"
                name="search"
                placeholder="Circulars Subject"
                required
              />
              <div className="valid-feedback">Looks good!</div>
            </div>
              <div className="col-md-1">
                <label htmlFor="month" className="form-label">
                  Month
                </label>
                <select
                  className="form-select"
                  id="month"
                  name="month"
                  required
                >
                  <option selected disabled value>
                    All
                  </option>
                  {months.map((month, index) => (
        <option key={index} value={month}>{month}</option>
      ))}
                </select>
                <div className="invalid-feedback">
                  Please select a valid state.
                </div>
              </div>
              <div className="col-md-1">
                <label htmlFor="year" className="form-label">
                  Year
                </label>
                <select className="form-select" id="year" name="year" required>
                  <option selected disabled value>
                    2024
                  </option>
                  <option>2023</option>
                  <option>2022</option>
                  <option>2021</option>
                  <option>2020</option>
                  <option>2019</option>
                  <option>2018</option>
                  <option>2017</option>
                  <option>2016</option>
                  <option>2015</option>
                  <option>2014</option>
                  <option>2013</option>
                  <option>2012</option>
                </select>
                <div className="invalid-feedback">
                  Please select a valid state.
                </div>
              </div>

              <div className="col-md-2" style={{ paddingTop: "31px" }}>
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        </div>
        </div>
        </div>
        </div>

        <Footer />
        <FooterPre />
      </div>
    </>
  );
};

export default Circulars;
