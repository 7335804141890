import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Services from "../pages/Services";
import Navbar2 from "./Navbar2";
import AyodhyaCity from './../pages/AyodhyaCity';
import "./Navbarstyle2.css";

import Schemes from './../pages/Schemes';
import Tenders from './../pages/Tenders';
import Budget from './../pages/Budget';
import RightToInformation from './../pages/RightToInformation';
import EGovernance from './../pages/EGovernance';
import SocialWelfareProfile from './../pages/SocialWelfareProfile';
import Municipalactivitiesprofile from "../pages/Municipalactivitiesprofile";
import Chairmansmessage from './../pages/Chairmansmessage';
import Executiveofficer from "../pages/Executiveofficer";
import AyodhyaMission from "../pages/AyodhyaMission";
import RoleFunctions from "../pages/RoleFunctions";
import SourcesFunds from "../pages/SourcesFunds";
import Expenses from "../pages/Expenses";
import OrganizationStructure from './../pages/OrganizationStructure';
import WardscoveredAyodhyaNN from "../pages/WardscoveredAyodhyaNN";
import Wardmohallas from "../pages/Wardmohallas";

import Accounting from "../pages/Department/Accounting";
import WaterWork from './../pages/Department/WaterWork';
import TownPlanning from './../pages/Department/TownPlanning';
import Tax from './../pages/Department/Tax';
import StreetLight from './../pages/Department/StreetLight';
import Pension from './../pages/Department/Pension';
import Health from './../pages/Department/Health';
import Record from './../pages/Department/Record';
import Construction from './../pages/Department/Construction';

import Staff from './../pages/Aboutus/Staff';

import NewsLetter from '../pages/ImportantLinks/NewsLetter';
import BalanceSheet from './../pages/ImportantLinks/BalanceSheet';
import LocalBodiesDirectorate from './../pages/ImportantLinks/LocalBodiesDirectorate';
import GovernmentOrders from './../pages/ImportantLinks/GovernmentOrders';
import ActsRulesManuals from './../pages/ImportantLinks/ActsRulesManuals';
import CitizenCharter from './../pages/ImportantLinks/CitizenCharter';
import Feedback from './../pages/ImportantLinks/Feedback';
import Report from './../pages/ImportantLinks/Report';
import InformationDesk from './../pages/ImportantLinks/InformationDesk';
import Map from './../pages/ImportantLinks/Map';

import WebInfoManager from './../Footer/WebInfoManager';

import Watersupply from "../pages/services/Watersupply";
import Roadconstruction from "../pages/services/Roadconstruction";
import Sewageconstruction from "../pages/services/Sewageconstruction";
import Sanitation from "../pages/services/Sanitation";
import Solidwaste from "../pages/services/Solidwaste";
import Recordsofbirths from "../pages/services/Recordsofbirths";
import Issuingtrade from "../pages/services/Issuingtrade";
import Towndevelopment from "../pages/services/Towndevelopment";

import Amrut from "../pages/schemes/Amrut";
import Digitalindia from "../pages/schemes/Digitalindia";
import Smartcities from "../pages/schemes/Smartcities";
import Statesponsored from "../pages/schemes/Statesponsored";
import Swachbharat from "../pages/schemes/Swachbharat";
import Streetlight from "../pages/services/Streetlight";

import Privacy from '../pages/footerpages/Privacy';
import ContactUs from './../pages/footerpages/Contactuslist';
import ContactUsList from '../pages/footerpages/Contactuslist';
import Faq from '../pages/footerpages/Faq';
import TermCondition from '../pages/footerpages/TermCondition';

import AboutAyodhyaCity from '../pages/AboutAyodhya/AyodhyaCity';
import Fairs from '../pages/AboutAyodhya/Fairs';
import GeographicalStatus from '../pages/AboutAyodhya/GeographicalStatus';
import Ghat from '../pages/AboutAyodhya/Ghat';
import HolyPlaces from '../pages/AboutAyodhya/HolyPlaces';
import HowtoReach from '../pages/AboutAyodhya/HowtoReach';
import Monuments from '../pages/AboutAyodhya/Monuments';
import Museums from '../pages/AboutAyodhya/Museums';
import NatureParks from '../pages/AboutAyodhya/NatureParks';
import Population from '../pages/AboutAyodhya/Population';
import ShoppingPlaces from '../pages/AboutAyodhya/ShoppingPlaces';

import PhotoGallery from '../pages/Gallery/PhotoGallery';
import PressRelease from '../pages/Gallery/PressRelease';

import RtiAct from '../pages/Gallery/RtiAct';

import AmrutReforms from '../pages/eGovernance/AmrutReforms';
import WaterSewerage from '../pages/eGovernance/WaterSewerage';
import GrievancesRedressal from '../pages/eGovernance/GrievancesRedressal';
import PropertyTax from '../pages/eGovernance/PropertyTax';
import AdvertisementTax from '../pages/eGovernance/AdvertisementTax';
import IssuancesofLicenses from '../pages/eGovernance/IssuancesofLicenses';
import Circulars from '../pages/eGovernance/Circulars';

const NavbarStyle2 = () => {
  const router2 = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Navbar2 /> <Home />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <Navbar2 /> <About />
        </>
      ),
    },
    {
      path: "socialwelfareprofile",
      element: (
        <>
          <Navbar2 /> <SocialWelfareProfile />
        </>
      ),
    },
    {
      path: "municipalactivitiesprofile",
      element: (
        <>
          <Navbar2 /> <Municipalactivitiesprofile />
        </>
      ),
    },
    {
      path: "chairmansmessage",
      element: (
        <>
          <Navbar2 /> <Chairmansmessage />
        </>
      ),
    },
    {
      path: "executiveofficer",
      element: (
        <>
          <Navbar2 /> <Executiveofficer />
        </>
      ),
    },
    {
      path: "ayodhyaMission",
      element: (
        <>
          <Navbar2 /> <AyodhyaMission />
        </>
      ),
    },
    {
      path: "roleFunctions",
      element: (
        <>
          <Navbar2 /> <RoleFunctions />
        </>
      ),
    },
    {
      path: "sourcesFunds",
      element: (
        <>
          <Navbar2 /> <SourcesFunds />
        </>
      ),
    },
    {
      path: "expenses",
      element: (
        <>
          <Navbar2 /> <Expenses />
        </>
      ),
    },
    {
      path: "staff",
      element: (
        <>
          <Navbar2 /> <Staff />
        </>
      ),
    },
    {
      path: "Wardmohallas",
      element: (
        <>
          <Navbar2 /> <Wardmohallas />
        </>
      ),
    },
    {
      path: "wardscoveredAyodhyaNN",
      element: (
        <>
          <Navbar2 /> <WardscoveredAyodhyaNN />
        </>
      ),
    },
    {
      path: "organizationStructure",
      element: (
        <>
          <Navbar2 /> <OrganizationStructure />
        </>
      ),
    },
    {
      path: "/AyodhyaCity",
      element: (
        <>
          <Navbar2 /> <AyodhyaCity />
        </>
      ),
    },
    {
      path: "/accounting",
      element: (
        <>
          <Navbar2 /> <Accounting />
        </>
      ),
    },
    {
      path: "/record",
      element: (
        <>
          <Navbar2 /> <Record />
        </>
      ),
    },
    {
      path: "/construction",
      element: (
        <>
          <Navbar2 /> <Construction />
        </>
      ),
    },
    {
      path: "/health",
      element: (
        <>
          <Navbar2 /> <Health />
        </>
      ),
    },
    {
      path: "/pension",
      element: (
        <>
          <Navbar2 /> <Pension />
        </>
      ),
    },
    {
      path: "/street-light",
      element: (
        <>
          <Navbar2 /> <StreetLight />
        </>
      ),
    },
    {
      path: "/tax",
      element: (
        <>
          <Navbar2 /> <Tax />
        </>
      ),
    },
    {
      path: "/town-planning",
      element: (
        <>
          <Navbar2 /> <TownPlanning />
        </>
      ),
    },
    {
      path: "/water-work",
      element: (
        <>
          <Navbar2 /> <WaterWork />
        </>
      ),
    },
    // =========== Important Links Start =========
    {
      path: "/newsLetter",
      element: (
        <>
          <Navbar2 /> <NewsLetter />
        </>
      ),
    },
    {
      path: "/balanceSheet",
      element: (
        <>
          <Navbar2 /> <BalanceSheet />
        </>
      ),
    },
    {
      path: "/acts-Rules-Manuals",
      element: (
        <>
          <Navbar2 /> <ActsRulesManuals />
        </>
      ),
    },
    {
      path: "/report",
      element: (
        <>
          <Navbar2 /> <Report />
        </>
      ),
    },
    {
      path: "/feedback",
      element: (
        <>
          <Navbar2 /> <Feedback />
        </>
      ),
    },
    {
      path: "/government-Orders",
      element: (
        <>
          <Navbar2 /> <GovernmentOrders />
        </>
      ),
    },
    {
      path: "/local-Bodies-Directorate",
      element: (
        <>
          <Navbar2 /> <LocalBodiesDirectorate />
        </>
      ),
    },
    {
      path: "/Citizen-Charter",
      element: (
        <>
          <Navbar2 /> <CitizenCharter />
        </>
      ),
    },
    {
      path: "/Information-Desk",
      element: (
        <>
          <Navbar2 /> <InformationDesk />
        </>
      ),
    },
    {
      path: "/Map",
      element: (
        <>
          <Navbar2 /> <Map />
        </>
      ),
    },
    // ============= Important Links End ==========

    {
      path: "/schemes",
      element: (
        <>
          <Navbar2 /> <Schemes />
        </>
      ),
    },
    {
      path: "/services",
      element: (
        <>
          <Navbar2 /> <Services />
        </>
      ),
    },
    {
      path: "/tenders",
      element: (
        <>
          <Navbar2 /> <Tenders />
        </>
      ),
    },
    {
      path: "/budget",
      element: (
        <>
          <Navbar2 /> <Budget />
        </>
      ),
    },
    {
      path: "/righttoinformation",
      element: (
        <>
          <Navbar2 /> <RightToInformation />
        </>
      ),
    },
    {
      path: "/eGovernance",
      element: (
        <>
          <Navbar2 /> <EGovernance />
        </>
      ),
    },
    {
      path: "/contactus",
      element: (
        <>
          <Navbar2 /> <ContactUs />
        </>
      ),
    },
    {
      path: "/contact-us-list",
      element: (
        <>
          <Navbar2 /> <ContactUsList />
        </>
      ),
    },
    {
      path: "/faq",
      element: (
        <>
          <Navbar2 /> <Faq />
        </>
      ),
    },
    // ================ Footer ================
    {
      path: "/webInfoManager",
      element: (
        <>
          <Navbar2 /> <WebInfoManager />
        </>
      ),
    },

    // ================ Footer End ==================

    //  ============== Service Scheeme =============
    {
      path: "/water-supply",
      element: (
        <>
          <Navbar2 /> <Watersupply />
        </>
      ),
    },
    {
      path: "/road-construction",
      element: (
        <>
          <Navbar2 /> <Roadconstruction />
        </>
      ),
    },
    {
      path: "/sewage-construction",
      element: (
        <>
          <Navbar2 /> <Sewageconstruction />
        </>
      ),
    },
    {
      path: "/sanitation",
      element: (
        <>
          <Navbar2 /> <Sanitation />
        </>
      ),
    },
    {
      path: "/street-light-maintenance",
      element: (
        <>
          <Navbar2 /> <Streetlight />
        </>
      ),
    },
    {
      path: "/solid-waste-management",
      element: (
        <>
          <Navbar2 /> <Solidwaste />
        </>
      ),
    },
    {
      path: "/records-of-births-and-deaths",
      element: (
        <>
          <Navbar2 /> <Recordsofbirths />
        </>
      ),
    },
    {
      path: "/issuing-trade-license",
      element: (
        <>
          <Navbar2 /> <Issuingtrade />
        </>
      ),
    },
    {
      path: "/town-development",
      element: (
        <>
          <Navbar2 /> <Towndevelopment />
        </>
      ),
    },
    {
      path: "/amrut",
      element: (
        <>
          <Navbar2 /> <Amrut />
        </>
      ),
    },
    {
      path: "/digital-india-program",
      element: (
        <>
          <Navbar2 /> <Digitalindia />
        </>
      ),
    },
    {
      path: "/smart-cities-mission",
      element: (
        <>
          <Navbar2 /> <Smartcities />
        </>
      ),
    },
    {
      path: "/state-sponsored-schemes",
      element: (
        <>
          <Navbar2 /> <Statesponsored />
        </>
      ),
    },
    {
      path: "/swach-bharat-mission",
      element: (
        <>
          <Navbar2 /> <Swachbharat />
        </>
      ),
    },
    {
      path: "/privacy",
      element: (
        <>
          <Navbar2 /> <Privacy />
        </>
      ),
    },

    //  ============== About Ayodhya =============
{
  path: "/Ayodhya-city",
  element: (
    <>
      <Navbar2 /> <AboutAyodhyaCity />
    </>
  ),
},
{
  path: "/Fairs",
  element: (
    <>
      <Navbar2 /> <Fairs />
    </>
  ),
},
{
  path: "/Geographical-status",
  element: (
    <>
      <Navbar2 /> <GeographicalStatus />
    </>
  ),
},
{
  path: "/Ghat",
  element: (
    <>
      <Navbar2 /> <Ghat />
    </>
  ),
},
{
  path: "/Holy-places",
  element: (
    <>
      <Navbar2 /> <HolyPlaces />
    </>
  ),
},
{
  path: "/How-to-reach",
  element: (
    <>
      <Navbar2 /> <HowtoReach />
    </>
  ),
},
{
  path: "/Monuments",
  element: (
    <>
      <Navbar2 /> <Monuments />
    </>
  ),
},
{
  path: "/Museums",
  element: (
    <>
      <Navbar2 /> <Museums />
    </>
  ),
},
{
  path: "/Population",
  element: (
    <>
      <Navbar2 /> <Population />
    </>
  ),
},
{
  path: "/Shopping-Places",
  element: (
    <>
      <Navbar2 /> <ShoppingPlaces />
    </>
  ),
},
{
  path: "/Nature-Parks",
  element: (
    <>
      <Navbar2 /> <NatureParks />
    </>
  ),
},

{
  path: "/gallery",
  element: (
    <>
      <Navbar2 /> <PhotoGallery />
    </>
  ),
},
{
  path: "/Press-Release",
  element: (
    <>
      <Navbar2 /> <PressRelease />
    </>
  ),
},
{
  path: "/Rti-Act",
  element: (
    <>
      <Navbar2 /> <RtiAct />
    </>
  ),
},
//  ============== E-Governance =============
{
  path: "/Amrut-Reforms",
  element: (
    <>
      <Navbar2 /> <AmrutReforms />
    </>
  ),
},
{
  path: "/Water-And-Sewerage-Charges",
  element: (
    <>
      <Navbar2 /> <WaterSewerage />
    </>
  ),
},
{
  path: "/Grievances-Redressal",
  element: (
    <>
      <Navbar2 /> <GrievancesRedressal />
    </>
  ),
},
{
  path: "/Property-Tax",
  element: (
    <>
      <Navbar2 /> <PropertyTax />
    </>
  ),
},
{
  path: "/Advertisement-Tax",
  element: (
    <>
      <Navbar2 /> <AdvertisementTax />
    </>
  ),
},
{
  path: "/Issuances-of-Licenses",
  element: (
    <>
      <Navbar2 /> <IssuancesofLicenses />
    </>
  ),
},
{
  path: "/Circulars",
  element: (
    <>
      <Navbar2 /> <Circulars />
    </>
  ),
},
{
  path: "/Terms-Conditions",
  element: (
    <>
      <Navbar2 /> <TermCondition />
    </>
  ),
},
  ]);
  return (
    <>
      <RouterProvider router={router2} />
    </>
  );
};



export default NavbarStyle2;
