import React from 'react'

const BrandImgItem = (props) => {
  return (
    <div>
      <div className="d-flex align-items-center bgcard2">
        <div className="container">
    
            <img src={props.url} className="card-img-top BrandImg" alt="..." />
         
        </div>
      </div>
    </div>
  );
}

export default BrandImgItem