import React from 'react'
import FooterPre from '../other/FooterPre';
import Footer from '../other/Footer';

const Wardmohallas = () => {
  return (
    <div>
      Wardmohallas
      <Footer />
      <FooterPre />
    </div>
  );
}

export default Wardmohallas