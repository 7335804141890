import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import Footer from "../other/Footer";
import FooterPre from "../other/FooterPre";

const AyodhyaMission = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Ayodhya Nagar Nigam Mission-Vision" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Ayodhya Nagar Nigam Mission-Vision"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Ayodhya Nagar Nigam Mission-Vision
              </div>
              <div className="card-body aboutus d-flex">
                <p>
                  The Mission and Vision of Nagar Nigam is to make the city a
                  cleaner, more beautiful city and discharging the duties
                  enlisted in the Municipal Act 1959 in order to provide better
                  civic facilities and services to the citizens of the city.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre />
    </>
  );
};

export default AyodhyaMission;
