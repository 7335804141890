import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Roadconstruction = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Road Construction" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Road Construction"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Road Construction
              </div>
              <div className="card-body aboutus d-flex">
                <p>
                The major responsibility of the Nagar Nigam in terms of road construction is to construct roads and bridges in their defined area using highest quality of materials and technology enabling proper management of roadways and traffic in their defined jurisdiction area. Certainly, Nagar Nigam works for planning, Design, Implementation & Monitoring of various Road and Bridge Construction work within its jurisdiction.
                 </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Roadconstruction;
