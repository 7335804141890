import React from "react";

const Map = () => {
  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-1 d-xs-none bgcolor">
            <span className="teststyle">Map of Ayodhya</span>
          </div>
          <div className="col-lg-8">
            <div className="responsive-map-container">
              <iframe
                title="myFrame"
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d113989.85843648406!2d82.05167565704582!3d26.770433563718782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sayodhya%20nagar%20nigam!5e0!3m2!1sen!2sin!4v1720677354650!5m2!1sen!2sin"
                id="frame1"
                height={275}
                width={"100%"}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>

          <div className="col">
            <div className="newsletter">
              <img className="img newsimg" src="../ayodya.png" alt="" />
              <a className="newsheading" href="../Footer/e-newsletter.pdf" target="_blank">
                <h3>Ayodhya NagarNigam Newsletter</h3></a>
              <hr />
              {/* <p className="newsdic">Subscribe to Our Newsletter</p> */}
              {/* <div className="input-group mb-3"> */}
                {/* <input */}
                  {/* // type="text" */}
                  {/* // className="form-control textbutton" */}
                  {/* // aria-label="Sizing example input" */}
                  {/* // aria-describedby="inputGroup-sizing-default" */}
                  {/* // placeholder="Enter  your e-mail" */}
                {/* // /> */}

                {/* <button */}
                  {/* // className="btn newsbutton" */}
                  {/* // type="button" */}
                  {/* // id="button-addon2" */}
                {/* // > */}
                  {/* Submit */}
                {/* </button> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map;
