
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Ghat = () => {
  const ImportItems = [
    { id: 1, itemName: "" },
    { id: 2, itemName: "" },
    { id: 3, itemName: "" },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Ghat" />
          <PageHeaderImg BreadName1="Home" BreadName2="Ghat" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Ghat</div>
              <div className="card-body aboutus">
              <h5 className="card-title">Guptar Ghat</h5>
              <p>Guptar Ghat is an important pilgrimage spot that is situated on the banks of the Sarayu River. It is believed that this is a place where Lord Ram drowned himself during Jal Samadhi’, to leave for his holy abode called the Vaikuntha. This place has various Hindu temples where aarti is held daily. This place is truly a worth visiting place.

</p>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default Ghat;