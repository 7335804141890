import React from "react";
import styles from "./Gallery.module.css";
import Heading from "./Heading";
import GalleryItem from "./GalleryItem";
import GalleryNews from "./GalleryNews";

const Gallery = () => {
  return (
    <>
      <div className={`${styles.gallery} container-fluid`}>
        <Heading title="Imgaes & Videos" HName="Gallery" />
        <div className="container-fluid">
          <div className="row">
            <div className="row col-lg-9">
              <GalleryItem />
            </div>

            <div className="col-lg-3">
              <div className="col-md-auto">
                <GalleryNews NewsHeading="News &amp; Updates" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
