
import React from 'react';

const FooterForm = () => {

  // Submit Form
  const SubmitForm = async (e) => {
    e.preventDefault();
    // Get Data
    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);
    console.log(payload);

    // const respond = await fetch("http://localhost:8080/demo", {
    //   method: "POST",
    //   body: JSON.stringify(payload),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // const data = await respond.json();
    // console.log(data);
  };
  return (
    <>
      <div className="FooterForm pb-2">
        <form onSubmit={SubmitForm}>
          <div className="">
            <label htmlFor="name" className="form-label formLableName">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="Name"
              aria-describedby="nameHelp"
            />
          </div>
          <div className="">
            <label htmlFor="email" className="form-label formLableName">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
            />
          </div>

          <div className="">
            <label htmlFor="phone" className="form-label formLableName">
              Phone Number
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              aria-describedby="phoneHelp"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="Suggestion" className="form-label formLableName">
              Suggestion
            </label>
            <textarea
              className="form-control"
              id="Suggestion"
              name="suggestion"
              rows={3}
            />
          </div>

          <button type="submit" className="btn btn-danger">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default FooterForm