import React from "react";


import { Link } from 'react-router-dom';
import Heading2 from './../../other/Heading2';
import PageHeaderImg from './../../other/PageHeaderImg';
import ImportLinks from './../../other/ImportLinks';
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";
const Staff = () => {
    const TableItems = [
      {
        id: 1,
        itemName: "Up-to-date status of reservation in various services of SC/ST personnel in the posts of direct recruitment/promotion (uncentred service) in urban bodies under Department of Urban Development.",
        itemName1:"View More",
      },
      {
        id: 2,
        itemName: "Details of officers and employees of centralized service in the city",
        itemName1:"View More",
      },
   
    ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Expenses" />
          <PageHeaderImg BreadName1="Home" BreadName2="Expenses" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Expenses</div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>{e.itemName}
                            <br />
                        <small>
                          <i className="fa-regular fa-file-pdf"></i> |
                          <b>Size:</b> 164 KB | <b>Lang:</b> Hindi |
                          <b>Uploading Date:</b> 10/05/21
                        </small>
                        </td>
                        <td>  <Link to="#" className="btn btn-warning">
                          {e.itemName1}
                        </Link></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre/>
    </>
  );
};

export default Staff;
