import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const GovernmentOrders = () => {
  const ImportItems = [
    {
      id: 1,
      itemName: "Important Government Orders issued in 2016",
      url: "http://urbandevelopment.up.nic.in/GO_main_menu_New2014.html",
      children: []
    },
    {
      id: 2,
      itemName: "Financial Acknowledgements",
      url: "#",
      children: [
        { id: 21, itemName: "Financial Acknowledgements - 2016-17", url: "http://urbandevelopment.up.nic.in/MainPageFinanc-IF16.html" },
        { id: 22, itemName: "Financial Acknowledgements - 2015-16", url: "http://urbandevelopment.up.nic.in/MainPageFinanc-IF15.html" },
        { id: 23, itemName: "Financial Acknowledgements - 2014-15", url: "http://urbandevelopment.up.nic.in/Financial_SenctionNewIF.html" },
        { id: 24, itemName: "Financial Acknowledgements - 2013-14", url: "http://urbandevelopment.up.nic.in/Financial_SenctionNew13-14IF.html" },
      ],
    },
    {
      id: 3,
      itemName: "Important Government Orders issued in recent years",
      url: "#",
      children: [
        { id: 31, itemName: "Section Government Orders", url: "http://urbandevelopment.up.nic.in/GO_Anubhag.html" },
        { id: 32, itemName: "Classwise Government Orders", url: "http://urbandevelopment.up.nic.in/GO_main_menu_New.html" },
      ],
    },
  ];

  const renderItems = (items) => (
    <ul>
      {items.map((item) => (
        <li key={item.id}>
          <a href={item.url}>
            <i className="fa-solid fa-angles-right" /> {item.itemName}
          </a>
          {item.children && item.children.length > 0 && renderItems(item.children)}
        </li>
      ))}
    </ul>
  );

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className="container breadcum">
          <Heading2 HName="Government Orders" />
          <PageHeaderImg BreadName1="Home" BreadName2="Government Orders" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Government Orders</div>
              <div className="card-body aboutus">
                {renderItems(ImportItems)}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
        <FooterPre />
      </div>
    </>
  );
};

export default GovernmentOrders;
