import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Sewageconstruction = () => {
  const ImportItems = [
    { id: 1, itemName: "Put sewage pipes in the inhabitant areas under all major and arterial roads." },
    { id: 2, itemName: "Collect sewage from houses and streets and transfer it to sewage treatment plants." },
    { id: 3, itemName: "Time to time cleaning nd maintenance of sewage pipelines such that no issues like sewage-clogging and sewage pipe breakage could cause insanitation in its municipal area." },
  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Sewage Construction" />
          <PageHeaderImg BreadName1="Home" BreadName2="Sewage Construction" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Sewage Construction</div>
              <div className="card-body aboutus">
              <p>
              The basic responsibility of Nagar Nigam towards Sewerage maintenance in its area is to construct efficient and smooth sewage system in the area. For this purpose, it manages and maintains the central sewerage and drainage lines under all major / arterial roads that transports sewage from the inhabitant areas of the Nagar Nigam towards sewage treatment plant that prevents outbreak of various diseases and help in maintenance of adequate public health. Ceratinly its main functions are:
              </p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Sewageconstruction;
