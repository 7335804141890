import React from 'react'
import { Link } from 'react-router-dom';

const GalleryNewsItem = () => {
   const items = [
     { id: 1, name: "Result 2023 - Uttarakhand Board of School Education" },
     { id: 2, name: "Notification" },
     { id: 3, name: "Land Acquistion- Preliminary Information" },
     { id: 4, name: "Details of portfolios to Hon’ble CM/Ministers" },
     { id: 5, name: "Corona(COVID19) Updates" },
     { id: 6, name: "Guidelines for uploading accessible documents" },
     { id: 7, name: "Uttarakhand State IT Policy 2016-2025" },
     { id: 8, name: "Uttarakhand Film Policy-2015" },
     { id: 9, name: "Uttarakhand Film Policy-2019" },
     { id: 10, name: "Public Notice - Property / Building Tax ByeLaws-2016" },
   ];
  return (
    <>
      <div className="card-body Gnewsstyle">
        {/* <ul> */}
          {/* {items.map((item) => ( */}
            {/* // <li key={item.id}> */}
              {/* <Link to="#">{item.name}</Link> */}
            {/* </li> */}
          {/* ))} */}
        {/* </ul> */}
        <div>
          <Link className="spotlightlink" to="#">
            View More <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

export default GalleryNewsItem