import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import { Link } from "react-router-dom";
import Footer from '../other/Footer';
import FooterPre from '../other/FooterPre';

const Budget = () => {
  const TableItems = [
    {
      id: 1,
      itemName: "Budget 2018-19",
      itemName1: "View More",
    },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Budget" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Budget"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Budget
              </div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                          <br />
                          <small>
                            <i className="fa-regular fa-file-pdf"></i> |
                            <b>Size:</b> 9.46 Mb | <b>Lang:</b> English |
                            <b>Uploading Date:</b> 28/10/18
                          </small>
                        </td>
                        <td>
                          {" "}
                          <Link to="#" className="btn btn-warning">
                            {e.itemName1}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>

          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Budget;
