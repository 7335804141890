import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Amrut = () => {
  const ImportItems = [
    
    { id: 1, itemName: "Creation and maintenance of water supply systems" },
    { id: 2, itemName: "Rehabilitation of old water supply" },
    { id: 3, itemName: "Rejuvenation of old Water bodies" },
    { id: 4, itemName: "Special water arrangement for difficult areas " },
  ];
    const ImportItems2 = [
    { id: 5, itemName: "Creation and maintenance of Decentralised, networked underground sewerage systems" },
    { id: 6, itemName: "Rehabilitation of old Sewage system and treatment plants" },
    { id: 7, itemName: "Recycling of water and reuse of wastewater" },
  ];

  const ImportItems3 = [
    { id: 8, itemName: "Faecal Sludge Management" },
    { id: 9, itemName: "Biological and Mechanical cleaning of sewers and septic tanks" },
   
  ];

  const ImportItems4 = [
    { id: 10, itemName: "To Construct and maintained effective storm drainage for prevent flooding" },
   
  ];

  const ImportItems5 = [
    { id: 11, itemName: "Construction of proper Footpaths/ walkways, sidewalks, foot over bridges and facilities for non-motorised transport" },
    { id: 12, itemName: "Construction and maintenance of multilevel parking" },
    { id: 13, itemName: "Bus Rapid Transport System" },
    { id: 14, itemName: "Ferry vessels for inland waterways" },
   
  ];

  const ImportItems6 = [
    { id: 15, itemName: "Lucknow (M Corp.)" },
 { id: 16, itemName: "Budaun (NPP)" },
 { id: 17, itemName: "Kanpur (M Corp.)" },
 { id: 18, itemName: "Banda (NPP)" },
 { id: 19, itemName: "Ghaziabad (M Corp.)" },
 { id: 20, itemName: "Lakhimpur (NPP)" },
 { id: 21, itemName: "Agra (M Corp.)" },
 { id: 22, itemName: "Hathras (NPP)" },
 { id: 23, itemName: "Meerut (M Corp.)" },
 { id: 24, itemName: "Lalitpur (NPP)" },
 { id: 25, itemName: "Varanasi (M Corp.)" },
 { id: 26, itemName: "Modinagar (NPP)" },
 { id: 27, itemName: "Allahabad (M Corp.)" },
 { id: 28, itemName: "Deoria (NPP)" },
 { id: 29, itemName: "Bareilly (M Corp.)" },
 { id: 30, itemName: "Pilibhit (NPP)" },
 { id: 31, itemName: "Moradabad (M Corp.)" },
 { id: 32, itemName: "Hardoi (NPP)" },
 { id: 33, itemName: "Aligarh (M Corp.)" },
 { id: 34, itemName: "Mainpuri (NPP)" },
 { id: 35, itemName: "Saharanpur (M Corp.)" },
 { id: 36, itemName: "Etah (NPP)" },
 { id: 37, itemName: "Gorakhpur (M Corp.)" },
 { id: 38, itemName: "Basti (NPP)" },
 { id: 39, itemName: "Firozabad (NPP)" },
 { id: 40, itemName: "Chandausi (NPP)" },
 { id: 41, itemName: "Loni (NPP)" },
 { id: 42, itemName: "Gonda (NPP)" },
 { id: 43, itemName: "Jhansi (M.Corp.)" },
 { id: 44, itemName: "Akbarpur (NPP)" },
 { id: 45, itemName: "Muzaffarnagar (NPP)" },
 { id: 46, itemName: "Khurja (NPP)" },
 { id: 47, itemName: "Mathura (NPP)" },
 { id: 48, itemName: "Azamgarh (NPP)" },
 { id: 49, itemName: "Shahjahanpur (NPP)" },
 { id: 50, itemName: "Ghazipur (NPP)" },
 { id: 51, itemName: "Rampur (NPP)" },
 { id: 52, itemName: "Mughalsarai (NPP)" },
 { id: 53, itemName: "Maunath Bhanjan (NPP)" },
 { id: 54, itemName: "Sultanpur (NPP)" },
 { id: 55, itemName: "Farrukhabad-cum-Fatehgarh (NPP)" },
 { id: 56, itemName: "Shikohabad (NPP)" },
 { id: 57, itemName: "Hapur (NPP)" },
 { id: 58, itemName: "Shamli (NPP)" },
 { id: 59, itemName: "Etawah (NPP)" },
 { id: 60, itemName: "Ballia (NPP)" },
 { id: 61, itemName: "Mirzapur-cum-Vindhyachal (NPP)" },
 { id: 62, itemName: "Baraut (NPP)" },
 { id: 63, itemName: "Bulandshahar (NPP)" },
 { id: 64, itemName: "Sambhal (NPP)" },
 { id: 65, itemName: "Amroha (NPP)" },
 { id: 66, itemName: "Fatehpur (NPP)" },
 { id: 67, itemName: "Rae Bareli (NPP)" },
 { id: 68, itemName: "Orai (NPP)" },
 { id: 69, itemName: "Bahraich (NPP)" },
 { id: 70, itemName: "Jaunpur (NPP)" },
 { id: 71, itemName: "Unnao (NPP)" },
 { id: 72, itemName: "Sitapur (NPP)" },
 { id: 73, itemName: "Faizabad (NPP)" },
 { id: 74, itemName: "Kasganj (NPP)" },
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Amrut" />
          <PageHeaderImg BreadName1="Home" BreadName2="Amrut" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">AMRUT Mission (Atal Mission for Rejuvenation and Urban Transformation)</div>
              <div className="card-body aboutus">
                <h5 className="card-title"> About the Mission:</h5>
                <p>The main aim of the AMRUT Mission is to provide basic amenities like water supply, sewerage, urban transport etc to households and build amenities in cities that can enhance the quality of life for all especially poor and disadvantaged people as national priority.</p>
                <h5 className="card-title"> Core Elements of the AMRUT Mission:</h5>
                <p>The core elements of the AMRUT mission lies in providing the basic amenities to the citizens which include:</p>
                
                <p><strong><i className="fa-solid fa-angles-right" /> Proper Supply of Water :</strong></p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      
                      
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p><strong><i className="fa-solid fa-angles-right" /> Sewerage Facility</strong></p>
                <ul>
                  {ImportItems2.map((e) => (
                    <li key={e.id}>


                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
                <p><strong><i className="fa-solid fa-angles-right" /> Septage</strong></p>
                 <ul>
                   {ImportItems3.map((e) => (
                     <li key={e.id}>
                       <i className="fa-solid fa-angles-right" /> {e.itemName}
                     </li>
                   ))}
                 </ul>
                 <p><strong><i className="fa-solid fa-angles-right" /> Storm Water Drainage</strong></p>
                 <ul>
                   {ImportItems4.map((e) => (
                     <li key={e.id}>
                       <i className="fa-solid fa-angles-right" /> {e.itemName}
                     </li>
                   ))}
                 </ul>
                 <p><strong><i className="fa-solid fa-angles-right" /> Urban Transport</strong></p>
                 <ul>
                   {ImportItems5.map((e) => (
                     <li key={e.id}>
                       <i className="fa-solid fa-angles-right" /> {e.itemName}
                     </li>
                   ))}
                 </ul>
                 <p><strong><i className="fa-solid fa-angles-right" />Green Spaces </strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" />Enhancing amenity value of cities by creating and upgrading green spaces, parks and recreation centres, especially for children </strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Purchase of land for projects or project related works</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Staff salaries of both the States/ULBs,</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Power</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Telecom</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Health</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Education</strong></p>
                 <p><strong><i className="fa-solid fa-angles-right" /> Wage employment programme and staff component</strong></p>

                 <h5 className="card-title"> Cities/ Towns in Uttar Pradesh that are Planned to be covered in AMRUT Mission are:</h5>
                 <ul>
                  {ImportItems6.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
       <FooterPre/>
      </div>
    </>
  );
};

export default Amrut;
