import React from 'react'
import { Link } from "react-router-dom";
const PageHeaderImg = ({ BreadName1, BreadName2 }) => {
  return (
    <div>
      <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="">
                <Link to="#">
                  <i className="fa-solid fa-house-chimney breadcumHome"></i>
                </Link>
                <Link to="#">{BreadName1}</Link>
              </li>
              <li className="">
                <Link to="#">{BreadName2}</Link>
              </li>
            </ol>
          </nav>
        </div>

   
  );
};

export default PageHeaderImg