import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import FooterPre from "../other/FooterPre";
import Footer from "../other/Footer";

const RoleFunctions = () => {
    const ImportItems = [
      { id: 1, itemName: "To ensure running and maintenance of civic services and facilities such as water supply, sewage system." },
      { id: 2, itemName: "To issue license/permits for shops, business establishments." },
      { id: 3, itemName: "To regulate opening/closing shops and markets." },
      { id: 4, itemName: "To run public health services." },
      { id: 5, itemName: "To maintain record of land and properties owned by it."},
  
  ];
  
     const TableItems = [
       { id: 1, itemName: "Assessment & Collection", itemName1: "Assessment of land and buildings, Mutation and Collection of Property Tax." },
       { id: 2, itemName: "Building", itemName1: "Scrutinizing building plan, checking unauthorized construction and routine inspection." },
       { id: 3, itemName: "Central Records", itemName1: "To maintain all old records such as birth and death registration, sanctioned building plans, assessment registers, et" },
       { id: 4, itemName: "Education", itemName1: "Imparting primary education and interacting with Government agencies and other organisations like NGO's." },
     ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Role & Functions" />
          <PageHeaderImg BreadName1="Home" BreadName2="Role & Functions" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Role & Functions</div>
              <div className="card-body aboutus">
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

                <p>
                  <strong>
                    Besides the above, following are the detailed role &
                    function of Nagar Nigam Ayodhya is given:
                  </strong>
                </p>
                <table className="table theadebg table-hover table-striped table-bordered">
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.itemName}</th>
                        <td>{e.itemName1}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre />
    </>
  );
};

export default RoleFunctions;
