import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';
import './Style.css'; // Import the CSS file

const Privacy = () => {

    return (
        <>
            <div className="container-fluid accountBGImg hide-on-mobile">
                <div className="container breadcum">
                    <Heading2 HName="Privacy Policy" />
                    <PageHeaderImg BreadName1="Home" BreadName2="Privacy Policy" />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row py-4">
                    <div className="col-lg-9">
                        <div className="card mb-3">
                            <div className="card-header h4 bg-warning">Privacy Policy</div>
                            <div className="card-body aboutus">
                                <p>Welcome to Nagar Nigam Ayodhya. We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we handle your information when you use our app.</p>

                                <h5 className="card-title"> 1. Information We Collect</h5>
                                <p>We do not collect any personal data from users of Nagar Nigam Ayodhya. Our app is designed to function without gathering any personal information from you.</p>

                                <h5 className="card-title"> 2. Use of Information</h5>
                                <p>Since we do not collect personal information, we do not use or process personal data for any purpose.</p>

                                <h5 className="card-title"> 3. Third-Party Services</h5>
                                <p>Our app does not use third-party services that collect, monitor, or analyze personal information.</p>
                                <h5 className="card-title"> 4. Data Security</h5>
                                <p>Even though we do not collect personal information, we prioritize the security of all data within our app. We use appropriate security measures to protect the app from unauthorized access and ensure a safe user experience.</p>
                                <h5 className="card-title"> 5. Children's Privacy</h5>
                                <p>Our app does not knowingly collect any information from children under the age of 13. As we do not collect personal data, we adhere to regulations concerning children's privacy.</p>
                                <h5 className="card-title"> 6. Changes to This Privacy Policy</h5>
                                <p>We may update our Privacy Policy from time to time. Any changes will be posted within the app. We encourage you to review this Privacy Policy periodically for any updates.</p>
                                <h5 className="card-title"> 7. Contact Us</h5>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us: 18003131277</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 hide-on-mobile">
                        <ImportLinks />
                    </div>
                </div>
                <div className="hide-on-mobile">

                <Footer  />
                <FooterPre  />
                </div>
            </div>
        </>
    );
};

export default Privacy;
