import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./CardSlider.css";
import CardSliderProduct from "./CardSliderProduct";

const CardSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
    
  };

  // Array
  const productdata = [
    {
      id: 1,
      imageurl: "../Cardsliders/AyodhyaParikrama.png",
      title: "Ayodhya Parikrama",
      discription: "Importance of 14 kosi,84 kosi parikrama and its route...",
    },
    {
      id: 2,
      imageurl: "../Cardsliders/dashboard.png",
      title: "Governance Profile",
      discription: "About department heads and their work....",
    },
    {
      id: 3,
      imageurl: "../Cardsliders/Figures.png",
      title: "Facts and Figures",
      discription:
        "Distribution of population, decadal growth rate, sexratio, density and literacy...",
    },
    {
      id: 4,
      imageurl: "../Cardsliders/govpro.png",
      title: "Waste Management",
      discription:
        "Regular house to house garbage collection by separating dry and wet ...",
    },
    {
      id: 5,
      imageurl: "../Cardsliders/waste-management.png",
      title: "Dashboard",
      discription:
        "The ongoing projects, their progress and facilities that public will get post their completion....",
    },
    {
      id: 4,
      imageurl: "../Cardsliders/AyodhyaParikrama.png",
      title: "Waste Management",
      discription:
        "Regular house to house garbage collection by separating dry and wet ...",
    },
    {
      id: 5,
      imageurl: "../Cardsliders/dashboard.png",
      title: "Dashboard",
      discription:
        "The ongoing projects, their progress and facilities that public will get post their completion....",
    },
  ];

  const cardproduct = productdata.map((item) => (
    <CardSliderProduct
      key={item}
      url={item.imageurl}
      heading={item.title}
      dic={item.discription}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      customTransition="all .5"
      transitionDuration={500}
    />
  ));

  return (
    <>
      <div className="container-fluid text-center">
        <Carousel responsive={responsive}>
          {cardproduct}
        </Carousel>
      </div>
      ;
    </>
  );
};

export default CardSlider;
