import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";


const Pension = () => {
    const ImportItems = [
      { id: 1, itemName: "To obtain pension forms related to Nagar Nigam service staff / officers, its testing and approval of pension/family pension along with other after retirement benefits." },
      { id: 2, itemName: "To take account of the payment of pension / family pension claims and providing solution to the respective banks." },
      { id: 3, itemName: "Take account of donations related to pension and to resolve them." },
      { id: 4, itemName: "Proceedings for complaint received/ published in newspaper/ suggestions related to pension / family pension and other claims." },
      { id: 5, itemName: "To maintain files of orders relating to pension."},
      { id: 6, itemName: "All work related to pending petitions in various courts/commissions and other similar entities related to pension."},

  ];
  
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Pension Department" />
          <PageHeaderImg BreadName1="Home" BreadName2="Pension Department" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Pension Department
              </div>
              <div className="card-body aboutus">
                <p>
                  Responsibilities of Nagar Nigam Ayodhya related to pension are
                  as follows:
                </p>
                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
};

export default Pension;
