import React from 'react'
import ImportLinks from './../../other/ImportLinks';
import { Link } from 'react-router-dom';
import PageHeaderImg from './../../other/PageHeaderImg';
import Heading2 from './../../other/Heading2';
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const InformationDesk = () => {
      const TableItems = [
        {
          id: 1,
          itemName: "Primary School Swargadwar Ayodhya",
        },
        {
          id: 2,
          itemName: "Primary School Shyamasadan Ayodhya",
        },
        {
          id: 3,
          itemName: "Primary School Janki Ghat Ayodhya",
        },
        {
          id: 4,
          itemName: "Primary School Shringar Ghat Ayodhya",
        },
        {
          id: 5,
          itemName: "Primary School Katra Ayodhya",
        },
        {
          id: 6,
          itemName: "Primary School Ranopali Ayodhya",
        },
        {
          id: 7,
          itemName: "Primary School Sonkhar Kund Ayodhya",
        },
        {
          id: 8,
          itemName: "Primary School Pali Temple Ayodhya",
        },
        {
          id: 9,
          itemName: "Junior Highschool  Pali Temple Ayodhya",
        },
        {
          id: 10,
          itemName: "Guru Vashishth Public School Vashistha Kund Ayodhya",
        },
        {
          id: 11,
          itemName: "Dr. Bhimrao Ambedkar Model School Vashistha Kund",
        },
        {
          id: 12,
          itemName: "Sitaram Amar Shiksha Niketan Vashistha Kund",
        },
        {
          id: 13,
          itemName: "Saraswati Vidya Mandir Educational Institution Vashistha Kund",
        },
        {
          id: 14,
          itemName: "Sanskrit Higher Secondary School Gayatri Bhawan",
        },
        {
          id: 15,
          itemName: "Blue Blaze Academy Kaziyana Ayodhya",
        },
        {
          id: 16,
          itemName: "Madarsa Aman Chaman School",
        },
        {
          id: 17,
          itemName: "Laxman Bal Vidya Mandir Inter College Vidyakund",
        },
        {
          id: 18,
          itemName: "Paramhans Teachers Training Graduate College Vidyakund",
        },
        {
          id: 19,
          itemName: "Raghuvar Sankrit Paathshala Swargdwar",
        },
        {
          id: 20,
          itemName: "Bhagwat Sanskrit Pathshaala Swargdwar",
        },
        {
          id: 21,
          itemName: "Veer Raghav Sanskrit Pathshaala Swargdwar",
        },
        {
          id: 22,
          itemName: "Shribal Mukund Madheshiya Sanskrit College Swargdwar",
        },
        {
          id: 23,
          itemName: "Avadh Vidya Mandir Junior High School Swargdwar",
        },
        {
          id: 24,
          itemName: "Little Rose Academy Urdu Bazaar Ayodhya",
        },
        {
          id: 25,
          itemName: "Mahesh Yogi Vedic Sanskrit College Jaisinghpur",
        },
        {
          id: 26,
          itemName: "Raghunath Das Smarak Former University Badi Chaavni Raiganj",
        },
        {
          id: 27,
          itemName: "Parma Academy Ramghat Ayodhya",
        },
        {
          id: 28,
          itemName: "Yogiraj Sanskrit University Nirmohi Bazaar Ayodhya",
        },
        {
          id: 29,
          itemName: "Anand Children Mahavidyalay Janki Ayodhya",
        },
        {
          id: 30,
          itemName: "Praveen Bal Vidya Mandir Pramodvan Ayodhya",
        },
        {
          id: 31,
          itemName: "Maharaja Public School Nirmohi Bazaar Ayodhya",
        },
        {
          id: 32,
          itemName: "Maharaj Inter College Matgaid Ayodhya",
        },
        {
          id: 33,
          itemName: "Janta Avadh Purv Madhyamik Vidyalay Golabazaar",
        },
        {
          id: 34,
          itemName: "Jagatguru Ramanandacharya Sanskrit Higher Secondary School",
        },
        {
          id: 35,
          itemName: "G.G.R Public School Begampura",
        },
        {
          id: 36,
          itemName: "Raj Gopal Sanskrit Mahavidyalay Meerapur",
        },
        {
          id: 37,
          itemName: "Umapati Sanskrit Mahavidyalay Meerapur",
        },
        {
          id: 38,
          itemName: "Dedraj Sanskrit Mahavidyalay Narayan Ashram",
        },
        {
          id: 39,
          itemName: "Roopkala Sanskrit Mahavidyalay Rampaidi Ayodhya",
        },
        {
          id: 40,
          itemName: "Madhusudan Vidya Mandir Junior School Saptsagar",
        },
        {
          id: 41,
          itemName: "Jwala Prasad Memorial School Lavkushnagar",
        },
        {
          id: 42,
          itemName: "Ravindra Kumar Munnalal Junior High School Tulsibadi",
        },
        {
          id: 43,
          itemName: "Shri Patanjali Vidya Mandir Ramghat Ayodhya",
        },
        {
          id: 44,
          itemName: "Shri Bodhayan Pathshaala Totadrimath Vibheeshankund",
        },
        {
          id: 45,
          itemName: "Talent Culture School Nazarbagh Ayodhya",
        },
        {
          id: 46,
          itemName: "Raghunath Inter College Sitakund",
        },
        {
          id: 47,
          itemName: "Hanumat Sanskrit Mahavidyalay Hanuman Garhi Ayodhya",
        },
        {
          id: 48,
          itemName: "Shri Ram Hospital Ayodhya",
        },
        {
          id: 49,
          itemName: "Sanskrit Pathshaala Bada Sthaan Ayodhya",
        },
        {
          id: 50,
          itemName: "Matritv Academy Mugalpura Ayodhya",
        },
        {
          id: 51,
          itemName: "Krishna Waaris Government Children Academy Mugalpur Ayodhya",
        },
        {
          id: 52,
          itemName: "Madhav Convent Public School Meerapur Bilandi Ayodhya",
        },
        {
          id: 53,
          itemName: "Umesh Chandra Kids Valley School Ranopali Ayodhya",
        },
        {
          id: 54,
          itemName: "K.S Saket P.G College Ayodhya",
        },
        {
          id: 55,
          itemName: "Chandravati Memorial School C.M.S Shaheed Gali Dantdhawan Kund",
        },
        {
          id: 56,
          itemName: "N.S.S Academy Vairagpur Ayodhya",
        },
        {
          id: 57,
          itemName: "Saarvbhaujm Vidyapeeth Sanskrit Paathshaala Jankighat Ayodhya",
        },
      ];

      const TableItems2 = [
        {
          id: 1,
          itemName: "Ayurvedic Hospital Meerapur Ayodhya",
        },
        {
          id: 2,
          itemName: "Veterinary Hospital Meerapur Ayodhya",
        },
        {
          id: 3,
          itemName: "Deenbandhu Eye Hospital Vaasudevghat Ayodhya",
        },
        {
          id: 4,
          itemName: "Ayodhya Eye Hospital Lavkush Nagar Ayodhya",
        },
        {
          id: 5,
          itemName: "Shri Ram Nethralaya Lavkush Nagar Ayodhya",
        },
        {
          id: 6,
          itemName: "Shri Ram Hospital Ayodhya",
        },
        {
          id: 7,
          itemName: "Rishabhdev Eye Hospital Ayodhya",
        },
        {
          id: 8,
          itemName: "Tulsi Womens Hospital Tulsi Nagar Ayodhya",
        },
        {
          id: 9,
          itemName: "Government Ayurvedic Hospital Vashistha Kund Ayodhya",
        },
        {
          id: 10,
          itemName: "Government Ayurvedic Hospital Kaniganj Ayodhya",
        },
        {
          id: 11,
          itemName: "Vishal Nursing Home Tulsinagar Ayodhya",
        },
        {
          id: 12,
          itemName: "Aarogya Maternity Home Vashistha Kund Ayodhya",
        },
        {
          id: 13,
          itemName: "Government Hospital Saket Ayodhya",
        },
      ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Information Desk" />
          <PageHeaderImg BreadName1="Home" BreadName2="Information Desk" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Information Desk</div>

              <p class="text-center pt-4"><b>List of schools established in area under Nagar Nigam, Ayodhya</b></p>

              <div className="card-body aboutus d-flex justify-content-center">
                
                <table className="table theadebg table-hover table-striped table-bordered">
                  
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                          <br />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <p class="text-center pt-4"><b>List of Hospitals established in area under Nagar Nigam, Ayodhya</b></p>
     <div className="card-body aboutus d-flex justify-content-center">
       
       <table className="table theadebg table-hover table-striped table-bordered">
         
         <tbody>
           {TableItems2.map((e) => (
             <tr key={e.id}>
               <th scope="row">{e.id}</th>
               <td>
                 {e.itemName}
                 <br />
               </td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
            </div>

          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
}

export default InformationDesk;