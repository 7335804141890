import React from 'react'

const Heading = ({ HName, title }) => {
  return (
    <>
      <div className="row d-flex text-center pt-4">
        <h1 className="textheading">
          <small className="textsmall">{title}</small>
          <br />
          {HName}
        </h1>
      </div>
    </>
  );
};

export default Heading