
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const HolyPlaces = () => {

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Holy Places" />
          <PageHeaderImg BreadName1="Home" BreadName2="Holy Places" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Holy Places</div>
              <div className="card-body aboutus">
              <p>The Ayodhya city situated on the banks of the River Saryu being associated with a mythological epic Ramayan as the birth place of Lord Ram has various temples that are prominent spiritual places for tourists to visit. Along with temples, Ayodhya also has various other tourist spots. Some are:</p>

              <h5 className="card-title"> Ram Janmabhoomi Temple</h5>
              <p>However, the area of Ram Janmabhoomi is disputed since the destruction of Babri Masjid built on it; yet due to its mythological association with Ramayan as the birth place of Lord Ram this place is a major tourist attraction in Ayodhya. This place possesses spiritual significance for Hindus and hence it is a place worth visiting.</p>

              <h5 className="card-title"> Hanuman Garhi</h5>
              <p>Another important temple that is situated in Ayodhya is Hanuman Garhi that is solely dedicated to Lord Hanuman. The temple is characterized by the 70 steep steps that should be scaled in order to reach the temple complex. Truly, this place is a must to visit.</p>

              <h5 className="card-title"> Treta Ke Thakur</h5>
              <p>Treta Ke Thakur is an ancient temple situated on the banks of the river Sarayu in Ayodhya. This temple has a beautiful idol of Lord Ram carved out in black sandstones.

</p>
              <h5 className="card-title"> Kanak Bhawan</h5>
              <p>This temple is built on an area that was supposed to house another temple which was gifted to Sita immediately after her marriage by Lord Ram. The temple was later renovated by King Vikramaditya of the Paramara dynasty and again rebuilt in 1891. This temple has marvellous architecture that makes it worth visiting.

</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default HolyPlaces;