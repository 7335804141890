import React from "react";
import ImportLinks from "../other/ImportLinks";
import Heading2 from "../other/Heading2";
import PageHeaderImg from "../other/PageHeaderImg";
import FooterPre from "../other/FooterPre";
import Footer from "../other/Footer";


const SocialWelfareProfile = () => {

    const ImportItems = [
    { id: 1, itemName: "Maintaining water supply"},
    { id: 2, itemName: "Preparation of ration card"},
    { id: 3, itemName: "Preparing birth/death records and certificates"},
    { id: 4, itemName: "Regulation of market places"},
    { id: 5, itemName: "Street Light Facility"},
    { id: 6, itemName: "Health Services"},
    { id: 7, itemName: "Sanitation and many more" },
   
  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Social Welfare Profile" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Social Welfare Profile"
          />
        </div>
      </div>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Social Welfare Profile
              </div>
              <div className="card-body">
                <p className="card-text">
                  Nagar Nigam Ayodhya is an urban local body (ULB) that
                  administers a Ayodhya-Faizabad region having population of
                  about 25 Lac.
                </p>
                <p className="card-text">
                  Under the urban local self-governance system, the Nagar Nigam
                  is administratively part of the district it is located in.
                  Nagar Nigam is entrusted with some duties and
                  responsibilities, as enshrined in the Constitutional Municipal
                  Corporations Act, 1959 and UP Municipalities Act, 1916. It
                  interacts directly with the State Government.
                </p>
                <p className="card-text">
                  The members of the Nagar Nigam are elected representatives for
                  a term of five years. The city is divided into wards according
                  to its population, and representatives are elected from each
                  ward. The members elect a president among themselves to
                  preside over and conduct meetings.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="../Announcements/slider.png"
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body aboutus">
                    <h5 className="card-title">Social Welfare</h5>
                    <p className="card-text">
                      <strong>
                        Nagar Nigam is responsible for many social welfare
                        activities such as:
                      </strong>
                    </p>
                    <ul>
                      {ImportItems.map((e) => (
                        <li key={e.id}>
                          <i className="fa-solid fa-angles-right" />{" "}
                          {e.itemName}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre />
    </>
  );
};

export default SocialWelfareProfile;
