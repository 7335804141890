import React from 'react'
import './Footer.css'
import FooterForm from './FooterForm';
import { Link } from 'react-router-dom';

const Footer = () => {
  const quickItem = [
    { id: 1, name: "About Ayodhya", url: "/about" },
    { id: 2, name: "Privacy & Policy", url: "/Privacy" },
    { id: 3, name: "Terms & Conditions", url: "/Terms-Conditions" },
    { id: 4, name: "Divya Ayodhya App", url: "https://play.google.com/store/apps/details?id=com.divyaayodhya" },
    { id: 5, name: "Ayodhya Map", url: "/Map" },
  ];

    const Contact = [
      { id: 1, icon:"fa-solid fa-phone", name: "+1800-313-1277" },
      { id: 2, icon:"fa-regular fa-clock", name: "10:00 AM to 05:00 PM on working days" },
      { id: 3, icon:"fa-regular fa-envelope", name: "nagarnigamayodhya@ gmail.com" },
      { id: 4, icon:"fa-solid fa-city", name: "Lajpat Nagar, Ayodhya, Uttar Pradesh, 224001" },
    ];
  return (
    <>
      <div className="container-fluid text-center Footerbg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">
              <img src="./mobileApp.png" className="img" alt="mobileApp" />
            </div>
            <div className="col-lg-3">
              <div className="row secndrow">
                <p className="FHeading">Download the Nagar Nigam Ayodhya App</p>
                <div className="col-lg-6">
                  <img
                    src="./Footer/qr-code.png"
                    className="img footerqr"
                    alt="qr"
                  />
                </div>
                <div className="col-lg-6">
                 <img
                   src="./Footer/apple-qr-code.png"
                   className="img footerqr"
                   alt="qr"
                 />
               </div>
                <div className="col-lg-6">
                  <a href="https://play.google.com/store/apps/details?id=com.ayoydhya.smartcity" target="_blank" rel="noopener noreferrer">
                    <img
                      src="./Footer/PlayStore.png"
                      className="img py-2"
                      alt="PlayStore"
                    />
                  </a>
                  </div>
                  <div className="col-lg-6">

                  <a href="https://apps.apple.com/in/app/ayodhya-nagar-nigam/id6523427457" target="_blank" rel="noopener noreferrer">
                    <img
                      src="./Footer/AppleStore.png"
                      className="img pt-2"
                      alt="AppleStore"
                    />
                  </a>
                </div>

              </div>
            </div>
            <div className="col-lg-2">
              <p className="FHeading">Quick Links</p>
              <div className="group">
                <ul>
                  {quickItem.map((item) => (
                    <li key={item.id}>
                      <Link to={item.url}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2">
              <p className="FHeading">Contact Us</p>
              <div className="group1">
                <ul>
                  {Contact.map((item) => (
                    <li key={item.id}>
                      <i className={`fa ${item.icon}`}></i>
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <p className="FHeading">Scan And Report</p>
              {/* <div className="col-lg-6"> */}
              {/* </div> */}
              <div className="FooterForm">
                {/* <FooterForm /> */}
                <img
                   src="./Footer/complain-scanner.png"
                   className="img footerqr"
                   alt="qr"
                 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer