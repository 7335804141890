import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Solidwaste = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="SOLID WASTE MANAGEMENT" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="SOLID WASTE MANAGEMENT"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                SOLID WASTE MANAGEMENT
              </div>
              <div className="card-body aboutus d-flex">
                <p>
                Solid Waste Management is an important aspect for maintaining proper cleanliness and health environment in an area. Therefore, Nagar Nigam is responsible for carrying out effective Solid Waste Mangement system in their municipal area that would ensure prevention of diseases and control of pollution in that area.

                </p>
                </div>
                <div className="card-body aboutus d-flex">
                <p>
               Solid Waste Management mainly involves collecting, treating, and disposing of solid material that is discarded because it has served its purpose or is no longer useful. Improper disposal of municipal solid waste can create unsanitary conditions, and these conditions in turn can lead to pollution of the environment and can cause outbreaks of vector-borne disease—that is, diseases spread by rodents and insects.
                 </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Solidwaste;
