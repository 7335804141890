import React from 'react'
import ImportLinks from './../../other/ImportLinks';
import { Link } from 'react-router-dom';
import PageHeaderImg from './../../other/PageHeaderImg';
import Heading2 from './../../other/Heading2';
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const AmrutReforms = () => {
      const TableItems = [
        {
          id: 1,
          itemName: "Amrut Reform Report 2017 - 2018",
          itemName1: "View",
        },
        {
            id: 2,
            itemName: "Amrut Reform Report 2018 - 2019",
            itemName1: "View",
        },
      ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Amrut Reforms" />
          <PageHeaderImg BreadName1="Home" BreadName2="Amrut Reforms" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Amrut Reforms</div>
              <div className="card-body aboutus d-flex justify-content-center">
                <table className="table theadebg table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TableItems.map((e) => (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>
                          {e.itemName}
                          <br />
                        </td>
                        <td>
                          <Link to="#" className="btn btn-warning btn-sm">
                            {e.itemName1}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
}

export default AmrutReforms