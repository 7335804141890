import React from 'react';
import "./Heroslider.css";
import HerosliderImg from './HerosliderImg';
import AlertsAnn from './AlertsAnn';
import ImportLinks from './ImportLinks';

const Heroslider = () => {
  
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-9 col-md-12 col-xs-12 pt-3">
            <HerosliderImg />
          </div>

          <div className="col-xl-3 col-md-12 col-sm-12 col-xs-12 pt-3 ">
            <AlertsAnn heading="Alerts & Announcements" />

            {/* <ImportantLinks /> */}
            <div className="overflow-auto" style={{ maxHeight: "380px" }}>
              <ImportLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Heroslider