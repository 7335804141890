
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Monuments = () => {

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Monuments" />
          <PageHeaderImg BreadName1="Home" BreadName2="Monuments" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Monuments</div>
              <div className="card-body aboutus">
              <h5 className="card-title">Moti Mahal</h5>
              <p>This place is a beautiful specimen of Mughal Architecture. Historically, Moti Mahal was the residence of wife of Nawab Shuja-ud-daula and was constructed in 1743 AD. Thus, having beautiful architecture this place is a worth to visit places in Ayodhya besides various temples and mosques situated there.</p>
              <h5 className="card-title">Mausoleum of Bahu Begum</h5>
              <p>Mausoleum of Bahu Begum is a historical monument located in Ayodhya that was built in 1816 as the resting place of Bahu Begam (wife of Nawab Shuja-ud-daula). This monument has rich architectural detailing and lush green garden. Truly, this place is best place to explore Nawabi architecture and is worth visiting.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default Monuments;