import React from 'react'
import ImportLinks from '../other/ImportLinks';
import Heading2 from '../other/Heading2';
import PageHeaderImg from '../other/PageHeaderImg';
import Footer from '../other/Footer';
import FooterPre from '../other/FooterPre';


const About = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="About Us" />
          <PageHeaderImg BreadName1="Home" BreadName2="About us" />
        </div>
      </div>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                About Nagar Nigam Ayodhya
              </div>
              <div className="card-body">
                <p className="card-text">
                  Ayodhya Nagar Nigam was established on 7 January, 1978 by
                  separating it from Nagar Nigam Faizabad. It was then known as
                  Nagar Nigam Parishad Ayodhya and started providing its
                  services to the citizens of Ayodhya.
                </p>
                <p className="card-text">
                  Later, in May 2017, through an order issued by the Government
                  of Uttar Pradesh, the Ayodhya Nagar Nigam was again merged
                  with Nagar Nigam Faizabad, after which the complete working of
                  Nagar Nigam Ayodhya and Faizabad came under Nagar Nigam
                  Ayodhya.
                </p>
                <p className="card-text">
                  At present, Ayodhya Nagar Nigam (Municipal Corporation) is the
                  governing body of the city of Ayodhya and former Faizabad. The
                  name of the district was changed to Ayodhya in November 2018.
                  The municipal corporation consists of democratically-elected
                  members, headed by a Mayor and administers the city's
                  infrastructure and public services.
                </p>
                <p className="card-text">
                  The urban local bodies in Uttar Pradesh are governed by two
                  important legislations, viz. UP Municipal Corporations
                  Adhiniyam, 1959 and UP Municipalities Act 1916. These two acts
                  specify the governance framework, spatial jurisdiction and the
                  functional domain of the urban local bodies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img
                    src="../Announcements/slider.png"
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body aboutus">
                    <h5 className="card-title">Who Govern Us</h5>
                    <p className="card-text">
                      <strong>
                        Being an institution of Local Government we have a
                        distinct difference between the two wings-
                      </strong>
                    </p>
                    <ul>
                      <li>
                        <i className="fa-solid fa-angles-right" /> Legislature
                        and
                      </li>
                      <li>
                        <i className="fa-solid fa-angles-right" /> Executive
                      </li>
                    </ul>
                    <p className="card-text">
                      Our Legislature is a governing Body. This Governing Body
                      which is elected by the citizens living within the
                      geographical area of ours. The geographical area has been
                      carved out into – electoral wards. Each ward elects a
                      representative to be sent to the Governing Body. The other
                      members of the Governing Body are:
                    </p>
                    <p className="card-text">
                      MLA MP, Municipal Commissioner and District Magistrate
                    </p>
                    <p className="card-text">
                      The election for the governing body is conducted by the
                      State Election Commission. Any person with more than 18
                      years is eligible to vote in the elections (certain
                      restrictions do apply. For details please contact the
                      State Election Commission which has its office at Faizabad
                      , Uttar Pradesh).
                    </p>
                    <p className="card-text">
                      The governing body works within the constitutional
                      framework and the acts made by the Uttar Pradesh
                      Government.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-8">
                  <div className="card-body aboutus">
                    <h5 className="card-title">What We Do</h5>
                    <p className="card-text">
                      <strong>
                        We do a lot of things to make life a better experience
                        in the geographical area specified for us by the state
                        government.Some of our works are :
                      </strong>
                    </p>
                    <ul>
                      <li>
                        <i className="fa-solid fa-angles-right" />
                        Setting up of Street Light Network and ensuring proper
                        street lighting in the nights.
                      </li>
                      <li>
                        <i className="fa-solid fa-angles-right" /> Establishing
                        Water Supply Network, maintaining it and ensuring
                        sufficient quantity of water is available for the
                        citizens.
                      </li>
                      <li>
                        <i className="fa-solid fa-angles-right" /> Establishment
                        of Road network and its maintenance.
                      </li>
                    </ul>
                    <p className="card-text">
                      Since its inception, Nagar Nigam Ayodhya has been
                      providing basic facilities to the citizens coming under
                      their jurisdiction.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src="../Announcements/slider.png"
                    className="img-fluid rounded-end"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <FooterPre/>
    </>
  );
}

export default About