import React from 'react'
import Heroslider from '../other/Heroslider'
import Announcment from '../other/Announcment';
import "./Home.css"
import CardSlider from '../other/CardSlider';
import Leaders from '../other/Leaders';
import Services from '../other/Services';
import Map from '../other/Map';
import Gallery from '../other/Gallery';
import SocialMedia from '../other/SocialMedia';
import BrandSlider from '../other/BrandSlider';
import Footer from '../other/Footer';
import FooterPre from '../other/FooterPre';


const Home = () => {
  return (
    <div>
      <Announcment />
      <Heroslider />
      <div className="d-flex parent justify-content-center">
        <p className="rowafter"></p>
      </div>
      <CardSlider />
      <div className="d-flex parent justify-content-center">
        <p className="rowafter"></p>
      </div>
      <Leaders />

      <div className="d-flex parent justify-content-center py-4">
        <p className="rowafter"></p>
      </div>
      <Services />
      <Map />
      <div className="d-flex parent justify-content-center py-4">
        <p className="rowafter"></p>
      </div>
      <Gallery />

      <div className="d-flex parent justify-content-center pt-3">
        <p className="rowafter"></p>
      </div>
      {/* <SocialMedia /> */}

      <div className="d-flex parent justify-content-center pt-3">
        <p className="rowafter"></p>
      </div>
      <BrandSlider />

      <div className="d-flex parent justify-content-center pt-3">
        <p className="rowafter"></p>
      </div>
      <Footer />
      <FooterPre/>
    
    </div>
  );
}

export default Home