import React from "react";
import LeadersTeam from "./LeadersTeam";
import Heading from "./Heading";

const Leaders = () => {
  // Array
  const productdata = [
    {
      id: 1,
      imageurl: "../Leaders/yogi.jpg",
      title: "Shri. Yogi Adityanath",
      discription: "Hon'ble Chief Minister, Uttar Pradesh",
      facebook: "https://www.facebook.com/MYogiAdityanath/",
      twitter: "https://x.com/myogiadityanath?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      instagram: "https://www.instagram.com/myogi_adityanath/?hl=en",
    },
    {
      id: 2,
      imageurl: "../Leaders/ArvindKumarSharma.png",
      title: "Shri. Arvind Kumar Sharma",
      discription: "Urban Development Minister",
      facebook: "https://www.facebook.com/aksharmabharatpage/",
      twitter: "https://x.com/aksharmaBharat?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      instagram: "https://www.instagram.com/aksharmabharat/?hl=en",
    },
    {
      id: 3,
      imageurl: "../Leaders/GirishPatiTripathi.png",
      title: "Shri. Girish Pati Tripathi",
      discription: "Mayor",
      facebook: "https://www.facebook.com/girish.tripathi.56",
      twitter: "https://x.com/i/flow/login?redirect_after_login=%2Fgirishpati",
      instagram: "https://www.instagram.com/girishpati.tripathi",
    },
    {
      id: 4,
      imageurl: "../Leaders/SantoshKumarSharma.png",
      title: "Shri. Santosh Kumar Sharma",
      discription: "Municipal Commissioner",
      facebook: "#",
      twitter: "https://x.com/i/flow/login?redirect_after_login=%2Fsantosharmaias",
      instagram: "#",
    },
  ];

  const teamproduct = productdata.map((item) => (
    <LeadersTeam
      key={item.id}
      url={item.imageurl}
      name={item.title}
      dic={item.discription}
      facebook={item.facebook}
      twitter={item.twitter}
      instagram={item.instagram}
    />
  ));

  return (
    <>
      <div className="container-fluid d-flex justify-content-center text-center Leaders">
        <div className="row">
          <Heading title="Nagar Nigam Ayodhya" HName="Meet Our Leaders" />
          <div className="col-sm-12 col-md-12 col-xl-12 col-xs-12">
            <div className="text-center d-lg-flex d-md-flex">
              {teamproduct}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaders;
