import React from "react";
import "../Navbar.css";
import { Link } from "react-router-dom";

const Navbar2 = () => {
  return (
    <div className="bgnav2">
      <div className="container">
        <nav className="navbar navbar-expand-lg bg-body-nav ">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <i className="fa fa-home"></i>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse Navstyle2"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    About Us
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/about">
                        About Nagar Nigam Ayodhya
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="dropdown-item" to="/#">
                        Profile{" "}
                        <i className="fa-solid fa-angle-right submenuarrow" />
                      </Link>
                      <ul className="dropdown-submenu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/SocialWelfareProfile"
                          >
                            Social Welfare Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/municipalactivitiesprofile"
                          >
                            Municipal Activities Profile
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link className="dropdown-item" to="#">
                        Message
                        <i className="fa-solid fa-angle-right submenuarrow" />
                      </Link>

                      <ul className="dropdown-submenu submenuu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/chairmansmessage"
                          >
                            Chairman’s Message
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/executiveofficer"
                          >
                            Executive Officer Message
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ayodhyaMission">
                        Ayodhya Nagar Nigam Mission / Vision
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/roleFunctions">
                        Role & Functions
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/sourcesFunds">
                        Sources of Funds
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/expenses">
                        Expenses
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/staff">
                        Staff
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/organizationStructure"
                      >
                        Organization Structure
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/wardmohallas">
                        Ward area/ boundries and mohallas
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/wardscoveredAyodhyaNN"
                      >
                        Mohalla & Wards covered by Ayodhya Nagar Nigam
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Ayodhya City
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/Ayodhya-city">
                        History of Ayodhya City
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Map">
                        Map
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/How-to-reach">
                        How to reach
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Geographical-status">
                        Geographical status
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Population">
                        Population
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                   <Link className="dropdown-item" to="/#">
                   Places to visit{" "}
                     <i className="fa-solid fa-angle-right submenuarrow" />
                   </Link>
                   <ul className="dropdown-submenu">
                     <li>
                       <Link
                         className="dropdown-item"
                         to="/Monuments"
                       >
                         Monuments
                       </Link>
                     </li>
                     <li>
                       <Link
                         className="dropdown-item"
                         to="/Museums"
                       >
                         Museums
                       </Link>
                     </li>
                     <li>
                         <Link
                           className="dropdown-item"
                           to="/Nature-Parks"
                         >
                           Nature & Parks
                         </Link>
                       </li>
                       <li>
                         <Link
                           className="dropdown-item"
                           to="/Holy-Places"
                         >
                           Holy Places
                         </Link>
                       </li>
                       <li>
                         <Link
                           className="dropdown-item"
                           to="/Fairs"
                         >
                           Fairs
                         </Link>
                       </li>
                       <li>
                         <Link
                           className="dropdown-item"
                           to="/Shopping-Places"
                         >
                           Shopping Places
                         </Link>
                       </li>
                       <li>
                         <Link
                           className="dropdown-item"
                           to="/Ghat"
                         >
                           Ghat
                         </Link>
                       </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Departments
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/water-work">
                        Water Works Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/accounting">
                        Accounting Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/street-light">
                        Street Light Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/health">
                        Health Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/construction">
                        Construction Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/town-planning">
                        Town Planning
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/pension">
                        Pension Depatment
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/record">
                        Record Department
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/tax">
                        Tax Department
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Schemes
                  </Link>
                  <ul className="dropdown-menu">
                    <li className="nav-item dropdown">
                      <Link
                        className="dropdown-item"
                        to="#"
                        style={{ width: "107%" }}
                      >
                        Central Sponsored Schemes
                        <i className="fa-solid fa-angle-right submenuarrow" />
                      </Link>
                      <ul className="dropdown-submenu submenuu">
                        <li>
                          <Link className="dropdown-item" to="/amrut">
                            AMRUT
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/smart-cities-mission"
                          >
                            Smart Cities Mission
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/swach-bharat-mission"
                          >
                            Swach Bharat Mission
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/digital-india-program"
                          >
                            Digital India Program
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/state-sponsored-schemes"
                      >
                        State Sponsored Schemes
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Services
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/water-supply">
                        Water Supply
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/road-construction">
                        Road Construction
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/sewage-construction">
                        Sewage Construction
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/sanitation">
                        Sanitation & cleanliness
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/street-light-maintenance"
                      >
                        Street Light Maintenance
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/solid-waste-management"
                      >
                        Solid Waste Management
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/records-of-births-and-deaths"
                      >
                        Records of Births and Deaths
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/issuing-trade-license"
                      >
                        Issuing Trade License
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/town-development">
                        Town Development
                      </Link>
                    </li>
                  </ul>
                </li>

              

                <li className="nav-item">
                  <Link className="nav-link" to="/tenders">
                    Tenders
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/budget">
                    Budget
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Right to Information
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/Rti-Act">
                        Right to Information ACT
                      </Link>
                      <Link className="dropdown-item" to="/newsletter">
                        Citizen Charter
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    E-Governance
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/Amrut-Reforms">
                        Amrut Reforms
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="https://crsorgi.gov.in/web/index.php/auth/login"
                      >
                        Registration of Birth & Death
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Water-And-Sewerage-Charges">
                        Water & Sewerage Charges
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="https://jansunwai.up.nic.in/">
                        Grievances Redressal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Property-Tax">
                        Property Tax
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Advertisement-Tax">
                        Advertisement Tax
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Issuances-of-Licenses">
                        Issuances of Licenses
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Circulars">
                        Circulars
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="https://complaint.pmoay.com/index.php/home/add_card"
                      >
                        Apply For ID Card
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="http://35.165.4.150/leave_mgmt/"
                      >
                        Leave Management
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Gallery
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/gallery">
                        Photo Gallery
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Press-Release">
                        Press Release
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    Contact Us
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/contactus">
                        Key Contact List
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/contactus">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/faq">
                        Frequently Asked Questions
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/feedback">
                        Feedback
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar2;
