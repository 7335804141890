import React from 'react'
import "./Announcment.css";

const Announcment = () => {
  return (
    <>
      <div className="d-flex flex-row announcment align-items-center">
        <div className="p-2 labelitem">
          <span>Latest Announcement!</span>
        </div>
        <div className="p-2 flex-grow-1">
          <marquee
            className="blink"
            behavior="scroll"
            direction="right"
            scrollamount={"07"}
          >
            Beta version !
          </marquee>
        </div>
      </div>
    </>
  );
}

export default Announcment