import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from "../../other/Footer";
import FooterPre from "../../other/FooterPre";

const Accounting = () => {
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Accounting Department" />
          <PageHeaderImg
            BreadName1="Home"
            BreadName2="Accounting Department"
          />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">
                Accounting Department
              </div>
              <div className="card-body aboutus d-flex">
                <p>Accounts Department of Nagar Nigam Ayodhya basically works on several criterions that are being given in our constitution. Our Accounts Department has a mission as per the Mandated by the Constitution of India, we promote accountability, transparency and good governance through high quality auditing and accounting and provide independent assurance to our stakeholders, the Legislature, the Executive and the Public, that public funds are being used efficiently and for the intended purposes.
               </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
      </div>
      <Footer />
      <FooterPre/>
    </>
  );
};

export default Accounting;
