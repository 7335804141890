
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';
import FooterForm from '../../other/FooterForm';

const Feedback = () => {

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Feedback" />
          <PageHeaderImg BreadName1="Home" BreadName2="Feedback" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Feedback</div>
              <div className="card-body aboutus">
              <p><strong>Complete the below form to send us your comments and feedback on the website. Your opinion, suggestions and feedback will be very much appreciated. If you provide us with your contact information, we will be able to answer your questions.</strong></p>
               <p>Fields marked with * are required.</p>
               <div className="FooterForm">
               <FooterForm />
               </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default Feedback;