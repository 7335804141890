import React from 'react'

const GalleryCardSlider = () => {
  const items = [
    { id: 1, name: "Budget 2023-24", imageUrl: "../Gallery/newsupdate.png" },
    { id: 2, name: "Budget 2023-24", imageUrl: "../Gallery/newsupdate.png" },
    { id: 3, name: "Budget 2023-24", imageUrl: "../Gallery/newsupdate.png" },
    { id: 4, name: "Budget 2023-24", imageUrl: "../Gallery/newsupdate.png" },
  ];

  return (
    <>
      <div id="carouselExampleDark" className="carousel carousel-dark slide">
        <div className="carousel-inner">
          {items.map((item) => (
            <div key={item.id} className={`carousel-item active`}>
              <img
                className={`img-fluid d-block w-100`}
                // className=""
                src={item.imageUrl}
                alt={item.name}
              />
              <div className="carousel-caption">
                <p className="galleryDate">{item.name}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev gprev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon prev-icon"
            aria-hidden="true"
          />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next gnext"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon next-icon"
            aria-hidden="true"
          />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}

export default GalleryCardSlider