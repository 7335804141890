import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Watersupply = () => {
  const ImportItems = [
    { id: 1, itemName: "According to Master Plan, planning, Design, Implementation & Monitoring of various Water Supply Schemes in its area." },
    { id: 2, itemName: "Execution and administration of various water supply projects like- set-up of new sources of water, development of new waterworks / water distribution stations, increasing capacity of existing water treatment plants / Intake wells / underground tanks, Construction of overhead tanks, design, procurement & laying of transmission pipelines and distribution pipelines, procurement & installation of valves etc. is carried out by floating public tenders & appointing capable contractors as approved by the competent sanctioning authority of Municipal Corporation." },
    { id: 3, itemName: "Issuance of new licenses & renewal of existing licenses for plumbers." },
    { id: 4, itemName: "Supply of water tankers in the deficient area and where any complaints are received about quality/quantity of water supplied through pipeline." },
    { id: 5, itemName: "Maintenance of all the water works, water treatment plants water distribution stations, valves, transmission pipelines and distribution pipelines is done either departmentally or by appointing private participants." },

  ];

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Water Supply" />
          <PageHeaderImg BreadName1="Home" BreadName2="Water Supply" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Water Supply</div>
              <div className="card-body aboutus">
                <h5 className="card-title"> Major responsibilities of this Division are:</h5>

                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};

export default Watersupply;
