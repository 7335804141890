import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const Swachbharat = () => {
  const ImportItems = [
    { id: 1, itemName: "Elimination of Open defecation" },
    { id: 2, itemName: "Eradication of Manual Scavenging" },
    { id: 3, itemName: "Modern and Scientific Municipal Solid Waste Management" },
    { id: 4, itemName: "To effect Behavioural change regarding healthy sanitation practices" },
    { id: 5, itemName: "Generate awareness about sanitation and its linkage with public health" },
    { id: 6, itemName: "Capacity Augmentation for ULB’s" },
    { id: 7, itemName: "To create an enabling environment for private sector participation in Capex (capital expenditure) and Opex (operation and maintenance)" },
  ];
  const ImportItems1 = [
    { id: 8, itemName: "Construction of household toilets including conversion of insanitary latrines into pour-flush latrines" },
    { id: 9, itemName: "Construction and maintenance of community toilets" },
    { id: 10, itemName: "Construction and maintenance of public toilets" },
    { id: 11, itemName: "Solid Waste Management" },
    { id: 12, itemName: "IEC & Public Awareness" },
    { id: 13, itemName: "Management of Capacity building and Administrative & Office Expenses" },

  ];
  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Swach Bharat Mission" />
          <PageHeaderImg BreadName1="Home" BreadName2="Swach Bharat Mission" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Swach Bharat Mission</div>
              <div className="card-body aboutus">
                <h5 className="card-title"> About the Mission:</h5>
                <p>As the population in India is increasing greatly with each passing year, basic sanitation and sewage facilities seems insufficient to support this huge population of people. Apparently, weak sanitation and untreated sewage is believed to have caused various health problems as well as water pollution. Thus, as an initiative to create a cleaner and greener India, Ministry of Urban Development along with Ministry of Drinking Water and Sanitation started Swachh Bharat Mission that aims at attaining hygiene, waste management and sanitation across the nation till 2nd October, 2019.

                </p>
                <h5 className="card-title">Key objectives of Swachh Bharat Mission are</h5>

                <ul>
                  {ImportItems.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>

                <h5 className="card-title">Components of Mission</h5>

                <ul>
                  {ImportItems1.map((e) => (
                    <li key={e.id}>
                      <i className="fa-solid fa-angles-right" /> {e.itemName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
       <FooterPre/>
      </div>
    </>
  );
};

export default Swachbharat;
