import React from 'react'

const Services = () => {
  return (
    <>
      <div className="container text-center py-2">
        <div className="row cardrow">
          <div className="col-lg-3 mb-2">
            <div className="card text-bg-dark service">
              <div className="card-img-overlay">
                <h5 className="card-title">Clean India</h5>
              </div>
              <i className="fa-solid fa-book-open bookicon"></i>
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="card text-bg-dark service1">
              <div className="card-img-overlay">
                <h5 className="card-title">Roads repairing</h5>
              </div>
              <i className="fa-solid fa-city bookicon"></i>
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="card text-bg-dark service2">
              <div className="card-img-overlay">
                <h5 className="card-title">Sewers and drains cleaning</h5>
              </div>
              <i className="fa-solid fa-building-columns bookicon"></i>
            </div>
          </div>
          <div className="col-lg-3 mb-2">
            <div className="card text-bg-dark service3">
              <div className="card-img-overlay">
                <h5 className="card-title">Construction of public toilets</h5>
              </div>
              <i className="fa-solid fa-id-card bookicon"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services