import React from "react";
// import './Header2.css';
import './Header.css';
import TopHeader from "../other/TopHeader";
import MiddleHeader2 from './MiddleHeader2';


const Header2 = () => {
  return (
    <div className="hide-on-mobile">
      <TopHeader />
      <MiddleHeader2/>
    </div>
  );
};

export default Header2;
