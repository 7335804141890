import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./Gallery.module.css";


const GalleryItem = () => {
 const items = [
   { id: 1, name: "Item 1", imageUrl: "../Gallery/1.png" },
   { id: 2, name: "Item 2", imageUrl: "../Gallery/2.png" },
   { id: 3, name: "Item 3", imageUrl: "../Gallery/3.png" },
   { id: 4, name: "Item 4", imageUrl: "../Gallery/4.png" },
   { id: 5, name: "Item 5", imageUrl: "../Gallery/5.png" },
   { id: 6, name: "Item 6", imageUrl: "../Gallery/6.png" },
   { id: 7, name: "Item 7", imageUrl: "../Gallery/7.png" },
   { id: 8, name: "Item 8", imageUrl: "../Gallery/8.png" },
 ];
  return (
    <>
      {items.map((item) => (
        <div key={item.id} className="col-sm-3 col-md-3 col-xl-3 pt-4">
          <div className={`card ${styles.glycard}`}>
            <div className={`${styles.imgsizebox}`}>
              <img
                src={item.imageUrl}
                alt={item.name}
                className={`img-fluid ${styles.imgsize}`}
              />
            </div>

            <div className="card-body">
              <p className={`card-text ${styles.textsize}`}>
                Some quick example.
              </p>
              <Link to="#" className={`cradlink ${styles.cradlink}`}>
                Go somewhere
                <i
                  className={`fa-solid fa-arrow-right ${styles.cardArrow}`}
                ></i>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default GalleryItem