import './App.css';

// import Header from './components/NavBar/Header';

import Header2 from './components/NavBar/Header2';
// import NavbarStyle1 from './components/NavBar/NavbarStyle1';
import NavbarStyle2 from './components/NavBar/NavbarStyle2';



function App() {
 
    return (
      <>    
        
        <Header2 />
        <NavbarStyle2/>
      </>
    );
}

export default App;